body,
html {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: $regular_font;
}

a {
    @include transition(linear 0.3s);
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.container {
    @media (min-width: 1200px) {
        max-width: 1180px;
    }
    @media (min-width: 1400px) {
        max-width: 1440px;
    }
    @media (min-width: 1600px) {
        max-width: 1540px;
    }
    @media (min-width: 1681px) {
        max-width: 1710px;
    }
    @include media-breakpoint-down(sm) {
        max-width: 100%;
    }
}

label {
    &.underline_label {
        font-size: 14px;
        color: $sslogray;
        margin-bottom: 0;
        display: block;
        line-height: normal;

        @include desktop-1399 {
            font-size: 13px;
        }
    }
}

.orange_clr{
    color:$carnation;
}

.form-control {
    font-size: 14px;
    height: 50px;
    border-radius: 5px;
    padding: 8px 12px;
    border: 1px solid $alto;
    background-color: $white;
    width: 100%;
    color: $mako;
    font-family: $regular_font;

    &:focus,
    &:hover,
    &:active {
        @include no-outline();
        @include box-shadow(none !important);
        border: 1px solid $alto;
        background-color: $white;
    }

    &.underline_input {
        height: auto;
        border: none;
        border-bottom: solid 1px $alto;
        border-radius: 0;
        font-size: 18px;
        padding: 5px 0 5px;
        line-height: normal;

        &:focus,
        &:hover,
        &:active {
            border-bottom: 1px solid $alto;
        }
    }

    &:disabled,
    &[readonly] {
        background-color: $white;
        cursor: auto;
    }
    &::-webkit-input-placeholder {
        color: $sslogray !important;
    }
    &::-moz-placeholder {
        color: $sslogray !important;
    }
    &::-ms-input-placeholder {
        color: $sslogray !important;
    }
    &::-moz-placeholder {
        color: $sslogray !important;
    }

    @include desktop-1680 {
        &.underline_input {
            font-size: 15px;
        }
    }
    @include desktop-1399 {
        height: 45px;
        &.underline_input {
            font-size: 14px;
        }
    }
    @include media-breakpoint-down(lg) {
        height: 40px;
        font-size: 13px;
        &.underline_input {
            font-size: 13px;
        }
    }
}

.medium_font{
    font-family: $medium_font;
}

.btn {
    padding: 15px 15px;
    cursor: pointer;
    border: none;
    font-size: 18px;
    border-radius: 5px;
    line-height: normal;
    font-weight: normal;

    &.min_180 {
        min-width: 180px;
    }
    &.btn_primary {
        color: $white;
        background-color: $themeblue;
        &:hover,
        &:focus,
        &:active {
            color: $white;
            background-color: $themeblue;
        }
    }
    &.accept_btn {
        color: $white;
        background-color: $deepsea;
        &:hover,
        &:focus,
        &:active {
            color: $white;
            background-color: $deepsea;
        }
    }
    &.reject_btn {
        color: $white;
        background-color: $carnation;
        &:hover,
        &:focus,
        &:active {
            color: $white;
            background-color: $carnation;
        }
    }
    &.disabled, 
    &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }

    &:hover,
    &:focus {
        @include no-outline();
        @include box-shadow(none);
    }

    @include desktop-1600{
        font-size: 16px;
        &.min_180 {
            min-width: 150px;
        }
    }
    @include desktop-1399{
        padding: 12px 15px;
        font-size: 15px;
        &.min_180 {
            min-width: 140px;
        }
    }
    @include media-breakpoint-down(lg){
        font-size: 14px;
        &.min_180 {
            min-width: 130px;
        }
    }
    @include media-breakpoint-down(md){
        &.min_180 {
            min-width: 120px;
        }
    }
    @include media-breakpoint-down(sm){
        padding: 12px 12px;
        &.min_180 {
            min-width: 110px;
        }
    }
}
