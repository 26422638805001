@import "../../../studio_store_medical_router/src/web/assets/scss/main.scss";

.header {
    .navbar {
        padding: 0;
        height: 109.5px;
        @include box-shadow(0 3px 20px rgba($black, 0.07));

        .navbar-brand {
            margin: 0;
            padding: 0;
            font-size: 30px;
            line-height: normal;
            color: $themeblue;
            font-family: $medium_font;
            &:hover,
            &:focus {
                color: $themeblue;
            }
            .logo {
                > img {
                    height: 60px;
                    border-radius:5px
                }
            }
        }

        .call_us_view {
            display: flex;
            align-items: center;
            margin-left: 70px;
            img {
                margin-right: 17px;
            }
            .content {
                p {
                    font-size: 14px;
                    color: $mako;
                    margin-bottom: 5px;
                    line-height: normal;
                }
                h6 {
                    font-size: 18px;
                    color: $mako;
                    margin-bottom: 0;
                    font-family: $medium_font;
                }
            }
        }

        .select_search_view {
            display:block;
            margin-left:auto !important;
            margin-right:auto;
            // margin-left: 70px;
            border: 1px solid $alto;
            border-radius: 6px;
            // display: flex;
            align-items: center;
            max-width: 767px;
            width: 100%;
            .brder {
                width: 2px;
                height: 30px;
                background-color: $mercury;
                display: inline-block;
                margin-left: 20px;
            }
            .form-control {
                border: 0;
                height: auto;
                border-radius: 6px;
                padding: 0;
            }
            .select_location {
                max-width: 180px;
                width: 100%;
                .input-group {
                    flex-wrap: nowrap;
                    .input-group-prepend {
                        padding: 12px 10px 11px 20px;
                    }
                    .drop_down_city {
                        width: calc(100% - 50px);
                        > .btn {
                            padding: 0;
                            font-size: 14px;
                            color: $mako;
                            display: block;
                            width: 100%;
                            height: 100%;
                            text-align: left;
                            position: relative;
                            padding-right: 15px;
                            min-height: 48px;
                            &:after {
                                font-family: "FontAwesome";
                                content: "\f0d7";
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                font-size: 16px;
                            }
                        }
                        .menu {
                            background-color: $white;
                            border-radius: 4px;
                            box-shadow: 0 3px 20px 0 rgba($black, 0.07);
                            margin-top: 8px;
                            position: absolute;
                            z-index: 2;
                            left: 0;
                            padding: 20px 20px 8px;
                            border: 1px solid $mercury;
                            &:before,
                            &:after {
                                content: "";
                                position: absolute;
                            }
                            &:before {
                                bottom: 100%;
                                left: 32px;
                                width: 0;
                                height: 0;
                                border-left: 4px solid transparent;
                                border-right: 4px solid transparent;
                                border-bottom: 5px solid $white;
                            }
                            &:after {
                                top: -4px;
                                left: 32px;
                                width: 7px;
                                height: 7px;
                                border: 1px solid $mercury;
                                border-left: 0;
                                border-top: 0;
                                transform: rotate(225deg);
                            }
                            > label {
                                font-size: 15px;
                                color: $mirage;
                                margin-bottom: 20px;
                                display: flex;
                                justify-content: space-between;
                                line-height: normal;
                                > .btn {
                                    font-size: inherit;
                                    border-radius: 0;
                                    img {
                                        min-height: 17px;
                                    }
                                }
                            }
                            .common_selectpicker {
                                min-width: 318px;
                                .select__control {
                                    display: flex;
                                    flex-direction: row-reverse;
                                    margin-bottom: 5px;
                                    border: 1px solid $alto;
                                    background-color: $white;
                                    border-radius: 23px;
                                    @include box-shadow(none !important);
                                    padding: 6px 13px;
                                    &:hover {
                                        border-color: $alto;
                                    }
                                    .select__indicators {
                                        margin-right: 8px;
                                    }
                                    .select__value-container {
                                        > [class*="css-"] {
                                            margin: 0;
                                            font-size: 12px;
                                        }
                                        .select__placeholder {
                                            font-size: 12px;
                                        }
                                    }
                                }
                                .select__menu {
                                    position: relative;
                                    .select__menu-list {
                                        .select__option {
                                            background-color: transparent;
                                            padding: 15px 0;
                                            line-height: normal;
                                            cursor: pointer;
                                            background: url("./images/small_left_arrow.svg") no-repeat right 10px center;
                                            &:not(:last-child) {
                                                border-bottom: 1px solid $mercury;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .search_input {
                width: 100%;
                position: relative;
                .input-group {
                    flex-wrap: nowrap;
                    .input-group-prepend {
                        padding: 13px 9px 13px 20px;
                    }
                    // .common_selectpicker {
                    //     width: calc(100% - 51px);
                    //     .select__control {
                    //         border: none;
                    //         background-color: transparent;
                    //         min-height: 48px;
                    //         padding: 13px 10px 13px 0;
                    //         .select__value-container {
                    //             > [class*="css-"] {
                    //                 margin: 0;
                    //             }
                    //             .select__placeholder {
                    //                 color: $sslogray;
                    //             }
                    //             .select__single-value {
                    //                 font-size: 14px;
                    //                 color: $mako;
                    //                 font-family: $medium_font;
                    //             }
                    //         }
                    //     }
                    //     .select__menu {
                    //         width: 360px;
                    //         background-color: $white;
                    //         border-radius: 4px;
                    //         box-shadow: 0 3px 20px 0 rgba($black, 0.07);
                    //         margin-top: 8px;
                    //         z-index: 2;
                    //         left: -48px;
                    //         padding: 20px 20px 8px;
                    //         border: 1px solid $mercury;
                    //         &:before,
                    //         &:after {
                    //             content: "";
                    //             position: absolute;
                    //         }
                    //         &:before {
                    //             bottom: 100%;
                    //             left: 32px;
                    //             width: 0;
                    //             height: 0;
                    //             border-left: 4px solid transparent;
                    //             border-right: 4px solid transparent;
                    //             border-bottom: 5px solid $white;
                    //         }
                    //         &:after {
                    //             top: -4px;
                    //             left: 32px;
                    //             width: 7px;
                    //             height: 7px;
                    //             border: 1px solid $mercury;
                    //             border-left: 0;
                    //             border-top: 0;
                    //             transform: rotate(225deg);
                    //         }
                    //         .select__menu-list {
                    //             .select__option {
                    //                 background-color: transparent;
                    //                 padding: 15px 0;
                    //                 line-height: normal;
                    //                 cursor: pointer;
                    //                 &:not(:last-child) {
                    //                     border-bottom: 1px solid $lightgray;
                    //                 }
                    //             }
                    //             .select__group {
                    //                 padding-bottom: 0;
                    //                 padding-top: 0;
                    //                 .select__group-heading {
                    //                     margin-bottom: 9px;
                    //                     padding-left: 0;
                    //                     padding-right: 0;
                    //                     font-size: inherit;
                    //                     color: inherit;
                    //                     font-weight: normal;
                    //                     display: flex;
                    //                     justify-content: space-between;
                    //                     text-transform: inherit;
                    //                     align-items: flex-start;
                    //                     span {
                    //                         display: inline-flex;
                    //                         line-height: normal;
                    //                         font-size: 15px;
                    //                         color: $rollingstone;
                    //                     }
                    //                 }
                    //             }
                    //             .no_message {
                    //                 .top {
                    //                     display: flex;
                    //                     justify-content: space-between;
                    //                     align-items: flex-start;
                    //                     margin-bottom: 35px;
                    //                     span {
                    //                         display: inline-flex;
                    //                         line-height: normal;
                    //                         font-size: 15px;
                    //                         color: $rollingstone;
                    //                     }
                    //                 }
                    //                 .img_view {
                    //                     text-align: center;
                    //                     padding-bottom: 25px;
                    //                     > img {
                    //                         margin-bottom: 21px;
                    //                     }
                    //                     h4 {
                    //                         font-size: 18px;
                    //                         font-family: $medium_font;
                    //                         color: $mako;
                    //                         margin-bottom: 8px;
                    //                         line-height: normal;
                    //                     }
                    //                     span {
                    //                         font-size: 14px;
                    //                         color: $sslogray;
                    //                         line-height: normal;
                    //                         display: block;
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                }
                .search_menu {
                    position: absolute;
                    top: 100%;
                    width: 360px;
                    background-color: $white;
                    border-radius: 4px;
                    box-shadow: 0 3px 20px 0 rgba($black, 0.07);
                    margin-top: 8px;
                    z-index: 2;
                    left: 0px;
                    padding: 20px 20px 8px;
                    border: 1px solid $mercury;
                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                    }
                    &:before {
                        bottom: 100%;
                        left: 32px;
                        width: 0;
                        height: 0;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-bottom: 5px solid $white;
                    }
                    &:after {
                        top: -4px;
                        left: 32px;
                        width: 7px;
                        height: 7px;
                        border: 1px solid $mercury;
                        border-left: 0;
                        border-top: 0;
                        transform: rotate(225deg);
                    }
                    .no_message {
                        .top {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            margin-bottom: 35px;
                            span {
                                display: inline-flex;
                                line-height: normal;
                                font-size: 15px;
                                color: $rollingstone;
                            }
                        }
                        .img_view {
                            text-align: center;
                            padding-bottom: 25px;
                            > img {
                                margin-bottom: 21px;
                            }
                            h4 {
                                font-size: 18px;
                                font-family: $medium_font;
                                color: $mako;
                                margin-bottom: 8px;
                                line-height: normal;
                            }
                            span {
                                font-size: 14px;
                                color: $sslogray;
                                line-height: normal;
                                display: block;
                            }
                        }
                    }
                    .group_heading {
                        margin-bottom: 9px;
                        display: flex;
                        justify-content: space-between;
                        span {
                            display: inline-flex;
                            line-height: normal;
                            font-size: 15px;
                            color: $rollingstone;
                        }
                        .btn {
                            font-size: inherit;
                        }
                    }
                    ul {
                        margin-bottom: 0;
                        max-height: 250px;
                        padding-right: 10px;
                        overflow: hidden;
                        overflow-y: auto;
                        &::-webkit-scrollbar {
                            width: 5px;
                            height: 5px;
                            border-radius: 10px;
                        }
                        &::-webkit-scrollbar-track {
                            -webkit-box-shadow: inset 0 0 6px $sslogray;
                            box-shadow: inset 0 0 6px $sslogray;
                            border-radius: 10px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: $themeblue;
                            border-radius: 10px;
                        }
                        li {
                            padding: 15px 0;
                            line-height: normal;
                            cursor: pointer;
                            &:not(:last-child) {
                                border-bottom: 1px solid $lightgray;
                            }
                        }
                        li span{
                            font-weight: 700;
                            font-size: 12px;
                        }
                    }
                }
            }
            .input-group {
                .input-group-prepend {
                    margin: 0;
                    .input-group-text {
                        padding: 0;
                        background-color: transparent;
                        border: 0;
                    }
                }
            }
        }

        .navbar-nav {
            background-color: $themeblue;
            align-items: center;
            border-radius: 6px;
            padding: 11.5px 29px;
            .nav-item {
                &:not(:last-child) {
                    margin-right: 5px;
                }
                span {
                    color: $white;
                }
                .nav-link {
                    font-size: 18px;
                    color: $white;
                    font-family: $medium_font;
                    padding: 0;
                }
            }
            &.mobile_view {
                display: none;
            }
        }

        .my_profile {
            background-color: transparent;
            border-radius: 0;
            padding: 0;
            .nav-item {
                &.dropdown {
                    .dropdown-toggle {
                        line-height: normal;
                        color: $mako;
                        font-size: 20px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 240px;
                        padding: 0;
                        > .first_letter {
                            height: 55px;
                            width: 55px;
                            border-radius: 50%;
                            margin-right: 9px;
                            background-color: $lightgray;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            color: $themeblue;
                        }
                        > img {
                            height: 55px;
                            width: 55px;
                            border-radius: 50%;
                            margin-right: 9px;
                        }
                        &:after {
                            margin-left: 14px;
                            vertical-align: baseline;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-left: 6px solid $sslogray;
                        }
                    }
                    .dropdown-menu {
                        padding: 0;
                        margin: 0;
                        border: 1px solid $mercury;
                        border-radius: 5px;
                        font-size: inherit;
                        box-shadow: 0 3px 20px 0 rgba($black, 0.07);
                        .dropdown-item {
                            padding: 0;
                            font-weight: normal;
                            color: inherit;
                            &:active,
                            &.active,
                            &:hover {
                                background-color: transparent;
                                border-radius: 0;
                            }
                            a {
                                font-size: 14px;
                                color: $mirage;
                                padding: 8px 15px;
                                display: block;
                            }
                        }
                    }
                }
            }
            &.mobile_view {
                display: none;
            }
        }

        @include desktop-1680 {
            .call_us_view {
                margin-left: 70px;
            }
            .select_search_view {
                max-width: 700px;
                margin-left: 60px;
            }
            .my_profile {
                .nav-item {
                    &.dropdown {
                        .dropdown-toggle {
                            font-size: 16px;
                            max-width: 180px;
                            > .first_letter {
                                height: 50px;
                                width: 50px;
                            }
                            > img {
                                height: 50px;
                                width: 50px;
                            }
                        }
                    }
                }
            }
            .navbar-brand {
                font-size: 29px;
            }
        }
        @include desktop-1440 {
            height: 100px;
            .call_us_view {
                margin-left: 70px;
            }
            .select_search_view {
                max-width: 580px;
                margin-left: 50px;
            }
        }
        @include desktop-1399 {
            height: 90px;
            .call_us_view {
                margin-left: 70px;
            }
            .select_search_view {
                max-width: 520px;
                margin-left: 30px;
            }
            .my_profile {
                .nav-item {
                    &.dropdown {
                        .dropdown-toggle {
                            max-width: 170px;
                            > .first_letter {
                                height: 45px;
                                width: 45px;
                            }
                            > img {
                                height: 45px;
                                width: 45px;
                            }
                        }
                    }
                }
            }
        }
        @include media-breakpoint-down(lg) {
            .call_us_view {
                margin-left: 70px;
                img {
                    margin-right: 10px;
                }
                .content {
                    h6 {
                        font-size: 14px;
                    }
                }
            }
            .select_search_view {
                margin-left: 25px;
                max-width: 400px;
                .select_location {
                    max-width: 165px;
                    .input-group {
                        .input-group-prepend {
                            padding: 9px 10px 9px 20px;
                            .input-group-text {
                                > img {
                                    max-width: 16px;
                                }
                            }
                        }
                        .drop_down_city {
                            width: calc(100% - 46px);
                            > .btn {
                                padding-right: 25px;
                                min-height: 40px;
                                font-size: 13px;
                                &:after {
                                    right: 10px;
                                }
                            }
                            .menu {
                                padding: 15px 15px 8px;
                                .common_selectpicker {
                                    min-width: 270px;
                                    .select__menu {
                                        .select__menu-list {
                                            .select__option {
                                                padding: 12px 0;
                                            }
                                        }
                                    }
                                }
                                > label {
                                    margin-bottom: 15px;
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
                .brder {
                    margin-left: 15px;
                }
                .search_input {
                    .input-group {
                        .input-group-prepend {
                            padding: 9px 9px 9px 20px;
                            .input-group-text {
                                > img {
                                    max-width: 16px;
                                }
                            }
                        }
                        .common_selectpicker {
                            width: calc(100% - 45px);
                            .select__control {
                                min-height: 40px;
                                padding: 9px 10px 9px 0;
                                .select__value-container {
                                    .select__single-value {
                                        font-size: 13px;
                                    }
                                }
                            }
                            .select__menu {
                                width: 260px;
                                padding: 15px 15px 8px;
                                .select__menu-list {
                                    .select__group {
                                        .select__group-heading {
                                            span {
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                    .select__option {
                                        padding: 12px 0;
                                    }
                                }
                            }
                        }
                    }
                    .search_menu {
                        width: 300px;
                        padding: 15px 15px 8px;
                        .group_heading {
                            span {
                                font-size: 13px;
                            }
                        }
                        ul {
                            li {
                                padding: 12px 0;
                                font-size: 13px;
                            }
                        }
                        .no_message {
                            .top {
                                margin-bottom: 25px;
                                span {
                                    font-size: 13px;
                                }
                            }
                            .img_view {
                                padding-bottom: 15px;
                                > img {
                                    max-width: 120px;
                                }
                                h4 {
                                    font-size: 14px;
                                }
                                span {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
            .navbar-nav {
                padding: 9px 15px;
                .nav-item {
                    .nav-link {
                        font-size: 14px;
                    }
                }
                .select_search_view {
                    margin-left: 0;
                    margin-bottom: 15px;
                    max-width: 100%;
                }
            }
            .my_profile {
                .nav-item {
                    &.dropdown {
                        .dropdown-toggle {
                            font-size: 14px;
                            max-width: 95px;
                            > .first_letter {
                                height: 35px;
                                width: 35px;
                                margin-right: 4px;
                            }
                            > img {
                                height: 35px;
                                width: 35px;
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            height: 70px;
            .navbar-collapse {
                position: absolute;
                left: 0;
                top: 100%;
                width: 100%;
                background-color: $white;
                padding: 0 15px 0;
                z-index: 11;
            }
            .navbar-toggler {
                padding: 0;
                border-radius: 0;
                color: inherit;
                border: none;
                font-size: inherit;
                margin-right: 20px;
                margin-left: auto;
                .navbar-toggler-icon {
                    background: url("./images/search_icon.svg") no-repeat center;
                }
            }
            .navbar-brand {
                font-size: 25px;
                .logo {
                    > img {
                        max-width: 140px;
                    }
                }
            }
            .navbar-nav {
                padding: 8px 15px;
                &.desktop_view {
                    display: none;
                }
                &.mobile_view {
                    display: flex;
                    flex-direction: row;
                }
                .nav-item {
                    display: inline-flex;
                }
            }
            .call_us_view {
                display: none;
            }
            .select_search_view {
                margin-left: 0;
                margin-bottom: 15px;
                max-width: 100%;
            }
            .my_profile {
                &.desktop_view {
                    display: none;
                }
                &.mobile_view {
                    display: flex;
                }
                .nav-item {
                    &.dropdown {
                        .dropdown-toggle {
                            max-width: 200px;
                        }
                    }
                }
            }
        }
        @include media-breakpoint-down(sm) {
            padding: 0 15px;
            .container {
                flex-wrap: nowrap;
            }
            .navbar-toggler {
                margin-right: 10px;
                .navbar-toggler-icon {
                    background-size: 16px;
                }
            }
            .navbar-brand {
                font-size: 22px;
                .logo {
                    > img {
                        max-width: 120px;
                    }
                }
            }
            .navbar-nav {
                padding: 6px 10px;
                .nav-item {
                    .nav-link {
                        font-size: 13px;
                    }
                }
            }
        }
        @include media-breakpoint-down(xs) {
            .select_search_view {
                display: block;
                max-width: inherit;
                border: none;
                border-radius: 0;
                margin-bottom: 0;
                .brder {
                    display: none;
                }
                .select_location {
                    border: 1px solid $alto;
                    border-radius: 6px;
                    max-width: 100%;
                    margin-bottom: 15px;
                    .input-group {
                        .drop_down_city {
                            .menu {
                                width: 100%;
                                .common_selectpicker {
                                    min-width: 100%;
                                }
                            }
                        }
                    }
                }
                .search_input {
                    border: 1px solid $alto;
                    border-radius: 6px;
                    margin-bottom: 15px;
                    .input-group {
                        .common_selectpicker {
                            .select__menu {
                                width: calc(100% + 48px);
                            }
                        }
                    }
                    .search_menu {
                        width: 100%;
                    }
                }
            }
            .my_profile {
                .nav-item {
                    &.dropdown {
                        .dropdown-toggle {
                            max-width: 100px;
                        }
                    }
                }
            }
        }
    }
}
