$black          : #000000;
$themeblue      : #324688;
$chambray       : #30458C;
$orange         : #f39f66;
$gamboge        : #E88A0C;
$white          : #ffffff;
$lightgray      : #f6f8fa;
$shark          : #1C1D1F;
$sslogray       : #8B8F95;
$mako           : #3E454F;
$alto           : #DBDBDB;
$halfbaked      : #8FCCCD;
$onahau         : #C7EDFF;
$mercury        : #E2E2E2;
$rollingstone   : #7C8188;
$mirage         : #1C213A;
$chambray       : #32557F;
$dustygray      : #979797;
$porcelain      : #EBECED;
$gallery        : #F0F0F0;
$deepsea        : #038B57;
$carnation      : #F67054;
$weborange      : #FFA500;
$malachite      : #11CA71;
$aquahaze       : #F2F6F8;
$iron           : #E0E2E5;
$alabaster      : #FAFAFA;

//Grid
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
) !default;

//Paths
$imageurl        :  "../../images";
$fonturl         :  "../../fonts";


//Font Family
$regular_font    : 'GTWalsheimPro-Regular';
$medium_font     : 'GTWalsheimPro-Medium';
$bold_font       : 'GTWalsheimPro-Bold';
$light_font      : 'GTWalsheimPro-Light';

//Font Face
@include font-face($regular_font, '#{$fonturl}/GTWalsheimPro-Regular', 'normal', 'normal');
@include font-face($medium_font, '#{$fonturl}/GTWalsheimPro-Medium', 'normal', 'normal');
@include font-face($bold_font, '#{$fonturl}/GTWalsheimPro-Bold', 'normal', 'normal');
@include font-face($light_font, '#{$fonturl}/GTWalsheimPro-Light', 'normal', 'normal');