@import "../../../studio_store_medical_router/src/web/assets/scss/main.scss";

.apps_sec {
    background-color: $mako;
    height: auto;
    a {
        display: block;
    }
    .banner_loader {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        color: $white;
        background-color: $mako;
    }

    .content_view {
        padding: 172px 0px 0px 120px;
        display: flex;
        flex-direction: column;
        h1 {
            font-size: 70px;
            margin-bottom: 47px;
            font-family: $bold_font;
            color: $white;
            line-height: normal;
        }
        .apps {
            display: flex;
            a {
                display: inline-flex;
                + a {
                    margin-left: 15px;
                }
            }
        }
    }

    .ss_view {
        position: relative;
        height: 669px;
        .screen1,
        .screen2 {
            position: absolute;
        }
        .screen1 {
            top: 50%;
            transform: translateY(-50%);
            left: 355px;
        }
        .screen2 {
            top: 40px;
        }
    }

    @include desktop-1680 {
        height: auto;
        // height: 640px;
        .content_view {
            h1 {
                font-size: 64px;
            }
            .apps {
                a {
                    > img {
                        max-width: 220px;
                    }
                }
            }
        }
        .ss_view {
            height: 640px;
            .screen1,
            .screen2 {
                max-width: 380px;
            }
            .screen1 {
                left: 320px;
            }
        }
    }
    @include desktop-1440 {
        height: auto;
        // height: 580px;
        .content_view {
            h1 {
                font-size: 58px;
            }
            .apps {
                a {
                    > img {
                        max-width: 200px;
                    }
                }
            }
        }
        .ss_view {
            height: 580px;
            .screen1,
            .screen2 {
                max-width: 350px;
            }
            .screen1 {
                left: 300px;
            }
        }
    }
    @include desktop-1399 {
        height: auto;
        // height: 530px;
        .content_view {
            padding: 160px 0 30px;
            h1 {
                font-size: 52px;
                margin-bottom: 40px;
            }
            .apps {
                a {
                    > img {
                        max-width: 180px;
                    }
                }
            }
        }
        .ss_view {
            height: 530px;
            .screen1,
            .screen2 {
                max-width: 320px;
            }
            .screen1 {
                left: 275px;
            }
            .screen2 {
                top: 35px;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        height: auto;
        // height: 440px;
        .content_view {
            padding: 140px 0 20px;
            h1 {
                font-size: 42px;
                margin-bottom: 35px;
            }
            .apps {
                a {
                    > img {
                        max-width: 150px;
                    }
                }
            }
        }
        .ss_view {
            height: 440px;
            .screen1,
            .screen2 {
                max-width: 260px;
            }
            .screen1 {
                left: 225px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        height: auto;
        // height: 380px;
        .content_view {
            padding: 110px 0 10px;
            h1 {
                font-size: 30px;
                margin-bottom: 35px;
            }
            .apps {
                a {
                    > img {
                        max-width: 100%;
                    }
                }
            }
        }
        .ss_view {
            height: 380px;
            .screen1,
            .screen2 {
                max-width: 220px;
            }
            .screen1 {
                left: 190px;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        height: auto;
        .content_view {
            padding: 30px 0 10px;
            h1 {
                font-size: 24px;
                margin-bottom: 25px;
            }
            .apps {
                justify-content: center;
                a {
                    > img {
                        max-width: 130px;
                    }
                }
            }
        }
        .ss_view {
            height: auto;
            padding: 0px 0px 10px;
            text-align: center;
            .screen1,
            .screen2 {
                position: relative;
                max-width: 250px;
            }
            .screen1 {
                top: 0;
                transform: none;
                left: 0;
            }
            .screen2 {
                top: 0;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .ss_view {
            .screen1,
            .screen2 {
                max-width: 145px;
            }
        }
    }
}
