.fullscreen-modal.modal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fullscreen-modal .modal-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #30458c;
}

.audio_section_container {
  height: 100%;
  background: #30458c;
  position: relative;
}

.audio_section_container .audio_buttons_container {
  display: flex;
  margin-bottom: 40px;
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.audio_section_container .audio_buttons_container .audio_button_container {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio_section_container
  .audio_buttons_container
  .audio_button_container
  .audio_mic_button {
  background: #fff;
  height: 50px;
  width: 50px;
  padding: 12px 15px;
  border-radius: 100%;
  cursor: pointer;
}

.audio_section_container
  .audio_buttons_container
  .audio_button_container
  .audio_button_end {
  background: #dc3545;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0 !important;
}

.local-participant {
  text-align: center;
  margin-bottom: 2em;
}

.remote-participants {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 2em 2em;
}

.waiting-loader {
  display: grid;
  justify-content: center;
  align-items: center;
  place-content: center;
  text-align: center;
  div > span{
    color: #fff;
    font-size: 18px;
  }
}

.audio-call-timer{
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.audio-participant {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 70vh;
}

.audio-participant .audio-img {
  width: 194px;
  height: 229px;
  border-radius: 26px;
}

.audio-participant .participant-text{
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  margin:16px;
}

.audio-participant:last-child {
  margin-right: 0;
}

.participant h3 {
  text-align: center;
  padding-bottom: 0.5em;
  color: #fff;
}
