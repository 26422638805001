@import "../../../studio_store_medical_router/src/web/assets/scss/main.scss";

.footer {
  padding: 140px 0 0px;
  background-color: $white;
  height: 387px;
  .footer_item {
    h3 {
      font-size: 20px;
      color: $mako;
      margin-bottom: 35.5px;
      font-family: $medium_font;
    }
    &.about {
      h1 {
        font-family: $medium_font;
        color: $themeblue;
        font-size: 50px;
        line-height: normal;
        margin-bottom: 24px;
      }
      p {
        margin-bottom: 28px;
        font-size: 16px;
        color: $mako;
        line-height: 22px;
      }
      ul {
        margin-bottom: 0;
        li {
          position: relative;
          &:not(:last-child) {
            margin-right: 21.5px;
            padding-right: 21.5px;
            &:after {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 1.5px;
              background-color: $chambray;
              height: 12px;
            }
          }
          a {
            display: inline-block;
          }
        }
      }
    }
    &.links {
      ul {
        margin-bottom: 0;
        li {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          a {
            color: $sslogray;
            font-size: 18px;
          }
        }
      }
    }
    &.promotions {
      img {
        width: 100%;
      }
    }
  }

  @include desktop-1440 {
    padding: 120px 0 60px;
  }
  @include desktop-1399 {
    padding: 100px 0 0px;
    .footer_item {
      &.about {
        h1 {
          font-size: 44px;
          margin-bottom: 15px;
        }
      }
      &.links {
        ul {
          li {
            &:not(:last-child) {
              margin-bottom: 15px;
            }
            a {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 80px 0 0px;
    .footer_item {
      h3 {
        font-size: 18px;
        margin-bottom: 30px;
      }
      &.about {
        h1 {
          font-size: 38px;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 25px;
          font-size: 14px;
        }
      }
      &.links {
        ul {
          li {
            &:not(:last-child) {
              margin-bottom: 12px;
            }
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    padding: 70px 0 0px;
    .footer_item {
      h3 {
        font-size: 16px;
        margin-bottom: 15px;
      }
      &.about {
        h1 {
          font-size: 30px;
        }
        p {
          margin-bottom: 20px;
        }
      }
      &.links {
        ul {
          li {
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            a {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 30px 0 0px;
    .footer_item {
      &.about {
        ul {
          margin-bottom: 30px;
        }
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .footer_item {
      &.links {
        ul {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.copyright {
  background-color: $lightgray;
  padding: 20px 0 30px;
  text-align: center;
  p {
    font-size: 16px;
    color: $mako;
    margin-bottom: 0;
    line-height: normal;
  }

  @include desktop-1399 {
    p {
      font-size: 15px;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 15px 0 20px;
    p {
      font-size: 14px;
    }
  }
  @include media-breakpoint-down(sm) {
    p {
      font-size: 13px;
    }
  }
}

.tap-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  font-size: 50px;
  height: 50px;
  width: 50px;
  button {
    background: #fff;
    box-shadow: 0 9px 25px 0 rgb(143 173 255 / 46%);
    height: 50px;
    width: 50px;
    border-radius: 5px;
    outline: none;
    border: none;
    &:hover {
      background: #fff;
      box-shadow: 0 9px 25px 0 rgb(143 173 255 / 46%);
    }
    .fa-arrow-up {
      font-size: 20px;
      color: #000;
    }
  }
}
