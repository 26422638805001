.child-content {
  min-height: calc(100vh - 477px);
}
.invalid_error {
  .input-group-prepend {
    .input-group-text {
      border-color: $carnation !important;
    }
  }
  .form-control {
    border-color: $carnation !important;
  }
}

.pass_hint,
.success,
.error {
  font-size: 13px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 0;
  position: absolute;
  bottom: calc(100% - 75px);
  line-height: normal;

  @include desktop-1399 {
    bottom: calc(100% - 63px);
    margin-top: 3px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 12px;
    bottom: calc(100% - 57px);
  }
  @include media-breakpoint-down(sm) {
    font-size: 11px;
    position: relative;
    bottom: 0;
  }
}

.pass_hint {
  color: $mako;
}

.error {
  color: $carnation;
}

.success {
  color: $deepsea;
}

.pos_unset {
  position: inherit;
  bottom: 0;
}

.slick-arrow {
  &.slick-prev,
  &.slick-next {
    width: auto;
    height: auto;
    z-index: 1;
    &:before {
      opacity: 1;
      content: none;
    }
  }

  @include desktop-1399 {
    &.slick-prev,
    &.slick-next {
      > img {
        max-width: 30px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &.slick-prev,
    &.slick-next {
      > img {
        max-width: 25px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &.slick-prev,
    &.slick-next {
      > img {
        max-width: 20px;
      }
    }
  }
}

.common_selectpicker {
  width: 100%;
  .select__control {
    border: 1px solid $lightgray;
    background-color: $white;
    border-radius: 0;
    min-height: 40px;
    @include box-shadow(none);
    padding: 6px 13px;
    &:focus,
    &:hover {
      @include no-outline();
      border-color: $lightgray;
    }
    .select__value-container {
      padding: 0;
      line-height: 18px;
      .select__placeholder {
        margin: 0;
        color: $rollingstone;
        font-size: 14px;
      }
      .select__input {
        color: $rollingstone;
      }
    }
    .select__indicators {
      &:hover {
        color: $mako !important;
      }
      .select__indicator-separator {
        display: none;
      }
      .select__indicator {
        padding: 0;
        color: $mako !important;
      }
    }
  }
  .select__menu {
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: $white;
    padding: 0;
    @include box-shadow(none);
    .select__menu-list {
      padding: 0;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $sslogray;
        box-shadow: inset 0 0 6px $sslogray;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $themeblue;
        border-radius: 10px;
      }
      .select__option {
        font-size: 16px;
        color: $mirage;
        &.select__option--is-focused {
          background-color: transparent;
        }
        &:hover {
          &.select__option--is-focused {
            background-color: transparent;
          }
        }
        p {
          cursor: pointer;
        }
      }
      .select__menu-notice {
        font-size: 14px;
        color: $black;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .select__control {
      .select__value-container {
        .select__placeholder {
          font-size: 13px;
        }
      }
    }
    .select__menu {
      .select__menu-list {
        .select__option {
          font-size: 13px;
        }
        .select__menu-notice {
          font-size: 13px;
        }
      }
    }
  }
}

.custom-checkbox {
  &.right {
    padding-left: 0;
    padding-right: 30px;
    &:not(:last-child) {
      margin-bottom: 22px;
    }
    .custom-control-input {
      right: 0;
      left: auto;
      width: 20px;
      height: 20px;
      top: 2px;
      &:checked {
        ~ .custom-control-label {
          color: $mako;
          font-family: $medium_font;
          &:before {
            border-color: $themeblue;
            background-color: $themeblue;
          }
          &:after {
            background: url("../../images/right_sign.svg") no-repeat center
              center;
          }
        }
      }
      &:focus {
        ~ .custom-control-label {
          &:before {
            @include box-shadow(none);
          }
        }
        &:not(:checked) {
          ~ .custom-control-label {
            &:before {
              border-color: $dustygray;
            }
          }
        }
      }
    }
    .custom-control-label {
      width: 100%;
      color: $sslogray;
      cursor: pointer;
      font-size: 16px;
      &:before,
      &:after {
        left: auto;
        right: -30px;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:before {
        border-radius: 3px;
        border-color: $dustygray;
      }
    }
  }

  @include desktop-1680 {
    &.right {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      .custom-control-input {
        width: 18px;
        height: 18px;
      }
      .custom-control-label {
        font-size: 14px;
        &:before,
        &:after {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    &.right {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      .custom-control-label {
        font-size: 13px;
      }
    }
  }
}

.doctor_box {
  border-radius: 4px;
  background-color: $white;
  padding: 20px;
  .media-left {
    margin-right: 10px;
    > img {
      min-width: 65px;
      width: 65px;
      height: 65px;
      border-radius: 4px;
    }
  }
  .media-body {
    padding: 0;
    .rating_view {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      line-height: normal;
      min-height: 18px;
      .left {
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          color: $shark;
          font-family: $medium_font;
          display: inline-block;
          margin-right: 6px;
          line-height: normal;
        }
      }
      .right {
        margin-left: 8px;
        span {
          font-size: 14px;
          color: $sslogray;
          font-family: $medium_font;
          display: inline-block;
          line-height: normal;
        }
      }
    }
    .heading_like {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      h3 {
        font-size: 18px;
        color: $shark;
        margin-bottom: 0;
        font-family: $medium_font;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 220px;
        display: inline-block;
        overflow: hidden;
        line-height: normal;
      }
      .badge {
        color: #000;
        font-size: 11px;
        padding: 5px 14px;
        background: #ffd5d1 !important;
        font-weight: 500;
      }
      .btn {
        font-size: 22px;
        color: $alto;
        border-radius: 0;
        line-height: normal;
        display: inline-block;
        &.fav_doc {
          color: $carnation;
        }
        + .btn {
          margin-left: 17px;
        }
      }
    }
    .fees_view {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .degree {
        margin-bottom: 0;
        font-size: 14px;
        color: $sslogray;
        line-height: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 160px;
        overflow: hidden;
        .on_off_status {
          position: relative;
          &.active {
            p {
              color: $malachite;
              &:before {
                background-color: $malachite;
              }
            }
          }
          p {
            font-size: 12px;
            font-family: $medium_font;
            margin-bottom: 8px;
            padding-left: 10px;
            &:before {
              content: "";
              height: 7.5px;
              width: 7.5px;
              background-color: transparent;
              border-radius: 50%;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
            }
          }
        }
      }
      .badge {
        color: #000;
        font-size: 11px;
        padding: 5px 14px;
        background: #ffd5d1 !important;
        font-weight: 500;
      }
      span {
        font-size: 14px;
        color: $themeblue;
        font-family: $medium_font;
        display: inline-block;
        line-height: normal;
      }
    }
    .language {
      font-size: 14px;
      color: $themeblue;
      margin-bottom: 8px;
      line-height: normal;
      min-height: 18px;
      display: inline-block;
      width: 180px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .availability {
      line-height: normal;
      font-size: 12px;
      font-family: $medium_font;
      color: $gamboge;
      margin-bottom: 20px;
    }
    .btn_primary {
      font-size: 14px;
      padding: 12px 21px;
    }
  }

  &.doctor_upcomming_box {
    .media-body {
      .fees_view {
        margin-bottom: 5px;
      }
      .doctor_calling_system {
        .call_btns {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  @include desktop-1680 {
    .media-left {
      > img {
        min-width: 60px;
        width: 60px;
        height: 60px;
      }
    }
    .media-body {
      .heading_like {
        h3 {
          font-size: 16px;
          max-width: 180px;
        }
        .btn {
          font-size: 20px;
        }
      }
      .fees_view {
        margin-bottom: 9px;
        .degree {
          max-width: 140px;
          .on_off_status {
            p {
              font-size: 12px;
              margin-bottom: 8px;
              padding-left: 10px;
            }
          }
        }
      }
      .availability {
        margin-bottom: 15px;
      }
      .btn_primary {
        padding: 12px 15px;
      }
    }
  }
  @include desktop-1399 {
    padding: 15px;
  }
  @include media-breakpoint-down(lg) {
    .media-left {
      > img {
        min-width: 55px;
        width: 55px;
        height: 55px;
      }
    }
    .media-body {
      .heading_like {
        h3 {
          font-size: 14px;
          max-width: 140px;
        }
        .btn {
          font-size: 18px;
          &.share {
            font-size: inherit;
          }
          > img {
            max-height: 17px;
          }
          + .btn {
            margin-left: 12px;
          }
        }
      }
      .fees_view {
        .degree {
          font-size: 13px;
          max-width: 110px;
          .on_off_status {
            p {
              font-size: 12px;
              margin-bottom: 8px;
              padding-left: 10px;
            }
          }
        }
        span {
          font-size: 13px;
        }
      }
      .language {
        font-size: 13px;
      }
      .btn_primary {
        padding: 10px 12px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .media-body {
      .heading_like {
        h3 {
          max-width: 130px;
        }
      }
    }
  }
}

.load_more {
  text-align: center;
  margin-top: 20px;
  .btn {
    font-size: 26px;
    color: $mako;
    font-family: $medium_font;
    border-radius: 0;
  }

  @include desktop-1680 {
    .btn {
      font-size: 24px;
    }
  }
  @include desktop-1399 {
    .btn {
      font-size: 22px;
    }
  }
  @include media-breakpoint-down(lg) {
    margin-top: 15px;
    .btn {
      font-size: 18px;
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 10px;
    .btn {
      font-size: 16px;
    }
  }
}

.modal-backdrop {
  background-color: rgba($mako, 0.8);
  &.show {
    opacity: 1;
  }
}

.modal {
  .modal-dialog {
    &.custom_modal {
      &.modal_md {
        @include media-breakpoint-up(sm) {
          max-width: 600px;
        }
      }
      &.modal_sm {
        @include media-breakpoint-up(sm) {
          max-width: 400px;
        }
      }
      &.common_close_btn {
        .modal-content {
          .modal-header {
            .close {
              display: block;
              padding: 0;
              right: 20px;
              top: 25px;
              margin: 0;
              position: absolute;
              height: 13px;
              width: 13px;
              opacity: 1;
              z-index: 11;
              background: url("../../images/close_icon.svg") no-repeat;
              &:focus {
                @include no-outline();
              }
              span {
                display: none;
              }
            }
          }
        }
      }
      .modal-content {
        border: none;
        border-radius: 10px;
        .modal-header {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding: 38px 30px 22px;
          border-bottom: 1px solid $alto;
          justify-content: center;
          .close {
            display: none;
          }
          .modal-title {
            font-size: 18px;
            font-family: $medium_font;
            color: $mako;
            line-height: normal;
          }
        }
        .modal-body {
          padding: 15px 30px;
        }
        .modal-footer {
          padding: 15px;
          border-top: 1px solid $alto;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          > * {
            margin: 0;
          }
        }
      }
      &.specialities_modal {
        .modal-content {
          .modal-body {
            &::-webkit-scrollbar {
              width: 3px;
              height: 4px;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $sslogray;
              box-shadow: inset 0 0 6px $sslogray;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: $themeblue;
              border-radius: 10px;
            }
            .custom-radio {
              padding-left: 0;
              &:not(:last-child) {
                margin-bottom: 15px;
              }
              .custom-control-input {
                &:checked {
                  ~ .custom-control-label {
                    color: $shark;
                    &:before {
                      border-color: $gamboge;
                      background-color: transparent;
                    }
                  }
                }
                &:focus {
                  ~ .custom-control-label {
                    &:before {
                      @include box-shadow(none);
                    }
                  }
                  &:not(:checked) {
                    ~ .custom-control-label {
                      &:before {
                        border-color: $dustygray;
                      }
                    }
                  }
                }
              }
              .custom-control-label {
                display: block;
                padding: 15px 15px;
                color: $shark;
                font-family: $medium_font;
                font-size: 14px;
                cursor: pointer;
                padding-left: 55px;
                text-align: left;
                &:before,
                &:after {
                  width: 100%;
                  height: 52px;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                }
                &:before {
                  border-radius: 12px;
                  background-color: transparent;
                  border: 1px solid $alto;
                }
                &:after {
                  background: none;
                }
              }
              > img {
                height: 29px;
                width: 29px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
              }
            }
            .btn {
              margin-top: 30px;
            }
          }
        }
      }
      &.change_password_modal {
        .modal-content {
          .modal-body {
            padding: 20px 40px;
            text-align: left;
            .changepw_info {
              color: $mako;
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 19px;
            }
            .form-group {
              margin-bottom: 30px;
            }
            .btn {
              font-size: 16px;
              font-family: $medium_font;
            }
          }
        }
      }
      &.change_password_success_modal {
        .modal-content {
          .modal-body {
            padding: 35px;
            text-align: center;
            .success_content {
              > img {
                margin-bottom: 40px;
              }
              h2 {
                font-size: 30px;
                font-family: $medium_font;
                color: $mako;
                line-height: normal;
                margin-bottom: 17px;
              }
              p {
                font-size: 16px;
                line-height: normal;
                color: $sslogray;
                margin-bottom: 42px;
              }
              .btn {
                font-size: 16px;
                min-height: 50px;
              }
            }
          }
        }
      }
      &.editprofile_modal {
        .modal-content {
          .modal-body {
            padding: 40px;
            .editprofile_view {
              .profile_upload {
                display: flex;
                align-items: flex-end;
                margin-bottom: 30px;
                .profile {
                  display: inline-block;
                  position: relative;
                  margin-right: 17px;
                  > img {
                    height: 109px;
                    width: 109px;
                    border-radius: 6px;
                    object-fit: cover;
                  }
                  .upload_btn {
                    width: 46px;
                    height: 46px;
                    position: absolute;
                    border-radius: 50%;
                    top: 0;
                    right: 0;
                    transform: translate(40%, -40%);
                    margin: 0;
                    background-color: $white;
                    @include box-shadow(0 3px 6px 0 rgba($black, 0.16));
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                  }
                }
                .btn {
                  font-size: 16px;
                  display: inline-flex;
                  color: $carnation;
                  font-family: $medium_font;
                  text-decoration: underline;
                  > img {
                    margin-right: 7px;
                  }
                }
              }
              .form-group {
                margin-bottom: 30px;
              }
              .btn_primary {
                font-size: 16px;
                font-family: $bold_font;
                min-height: 50px;
              }
            }
          }
        }
      }
      &.wishlist_modal {
        .modal-content {
          .modal-header {
            border-bottom: none;
          }
          .modal-body {
            text-align: center;
            padding: 38px 50px;
            p {
              margin-bottom: 0;
              line-height: normal;
              color: $carnation;
              font-size: 16px;
            }
          }
          .modal-footer {
            .btn {
              color: $mako;
              font-size: 15px;
              font-family: $medium_font;
            }
          }
        }
      }
      &.logout_modal {
        .modal-content {
          .modal-header {
            border-bottom: none;
          }
          .modal-body {
            text-align: center;
            padding: 10px 50px 50px;
            p {
              margin-bottom: 0;
              line-height: normal;
              color: $sslogray;
              font-size: 16px;
            }
          }
          .modal-footer {
            display: flex;
            justify-content: space-around;
            padding: 0px;
            .divider {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                width: 1px;
                height: 25px;
                background-color: $alto;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .btn {
              color: $sslogray;
              font-size: 15px;
              font-family: $medium_font;
              padding: 13px ​30px;
              width: 50%;
              ~ .btn {
                color: $mako;
              }
            }
          }
        }
      }
      &.stripe_payment_modal {
        .modal-dialog {
          width: 500px !important;
          .modal-content {
            .modal-header {
              border-bottom: none;
            }
            .modal-body {
              text-align: center;
              padding: 10px 50px 50px;
              p {
                margin-bottom: 0;
                line-height: normal;
                color: $sslogray;
                font-size: 16px;
              }
            }
            .modal-footer {
              display: flex;
              justify-content: space-around;
              padding: 0px;
              .divider {
                position: relative;
                &:after {
                  content: "";
                  position: absolute;
                  width: 1px;
                  height: 25px;
                  background-color: $alto;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
              .btn {
                color: $sslogray;
                font-size: 15px;
                font-family: $medium_font;
                padding: 13px ​30px;
                width: 50%;
                ~ .btn {
                  color: $mako;
                }
              }
            }
          }
        }
      }
      &.cancel_appointment {
        .modal-content {
          .modal-header {
            border-bottom: none;
          }
          .modal-body {
            text-align: center;
            padding: 10px 50px 50px;
            p {
              margin-bottom: 0;
              line-height: normal;
              color: $sslogray;
              font-size: 16px;
            }
          }
          .modal-footer {
            display: flex;
            justify-content: space-around;
            padding: 0;
            .divider {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                width: 1px;
                height: 25px;
                background-color: $alto;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .btn {
              color: $sslogray;
              font-size: 15px;
              font-family: $medium_font;
              width: 50%;
              padding: 13px ​30px;
              ~ .btn {
                color: $mako;
              }
            }
          }
        }
      }
      &.write_review_modal {
        .modal-content {
          .modal-header {
            border-bottom: none;
          }
          .modal-body {
            .form-group {
              margin-bottom: 25px;
              .underline_label {
                font-size: 16px;
                margin-bottom: 18px;
              }
              .form-control {
                padding: 20px 15px;
              }
            }
          }
          .modal-footer {
            padding: 18px 15px;
            justify-content: center;
            .btn {
              color: $mako;
              font-size: 15px;
              font-family: $medium_font;
            }
          }
        }
      }
      &.doctorappointment_modal {
        &.doctorappointmentreject_modal {
          .modal-content {
            .modal-body {
              padding: 0px 20px 20px;
              .form-control {
                padding: 15px;
                margin-top: 20px;
              }
            }
          }
        }
        .modal-content {
          .modal-header {
            border-bottom: none;
          }
          .modal-body {
            text-align: center;
            padding: 0px 45px 30px;
            > p {
              margin-bottom: 20px;
              line-height: normal;
              color: $sslogray;
              font-size: 16px;
            }
            span {
              font-size: 16px;
              color: $sslogray;
              span {
                color: $shark;
              }
            }
          }
          .modal-footer {
            display: flex;
            justify-content: space-around;
            padding: 0;
            .divider {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                width: 1px;
                height: 25px;
                background-color: $alto;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .btn {
              color: $sslogray;
              font-size: 15px;
              font-family: $medium_font;
              width: 50%;
              padding: 13px 30px;
              ~ .btn {
                color: $mako;
              }
            }
          }
        }
      }

      @include desktop-1399 {
        &.write_review_modal {
          .modal-content {
            .modal-body {
              .form-group {
                margin-bottom: 20px;
                .underline_label {
                  font-size: 13px;
                }
                .form-control {
                  padding: 15px ​15px;
                }
              }
            }
          }
        }
        &.change_password_modal {
          .modal-content {
            .modal-body {
              .changepw_info {
                font-size: 15px;
              }
              .form-group {
                margin-bottom: 30px;
              }
              .btn {
                font-size: 13px;
              }
            }
          }
        }
        &.editprofile_modal {
          .modal-content {
            .modal-body {
              padding: 25px;
              .editprofile_view {
                .profile_upload {
                  .profile {
                    > img {
                      height: 80px;
                      width: 80px;
                    }
                    .upload_btn {
                      width: 36px;
                      height: 36px;
                      > img {
                        max-height: 15px;
                      }
                    }
                  }
                  .btn {
                    font-size: 13px;
                  }
                }
                .btn_primary {
                  font-size: 13px;
                  min-height: auto;
                }
              }
            }
          }
        }
        &.doctorappointment_modal {
          &.doctorappointmentreject_modal {
            .modal-content {
              .modal-body {
                .form-control {
                  padding: 13px;
                }
              }
            }
          }
          .modal-content {
            .modal-body {
              > p {
                font-size: 14px;
              }
              span {
                font-size: 14px;
              }
            }
            .modal-footer {
              .btn {
                font-size: 13px;
              }
            }
          }
        }
      }
      @include media-breakpoint-down(lg) {
        .modal-content {
          .modal-header {
            padding: 30px 25px 15px;
            .modal-title {
              font-size: 16px;
            }
          }
          .modal-body {
            padding: 15px 25px;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        .modal-content {
          .modal-header {
            padding: 25px 20px 12px;
          }
          .modal-body {
            padding: 15px 20px;
          }
        }
        &.change_password_modal {
          .modal-content {
            .modal-body {
              padding: 15px 20px;
              .changepw_info {
                font-size: 14px;
                margin-bottom: 15px;
              }
            }
          }
        }
        &.editprofile_modal {
          .modal-content {
            .modal-body {
              padding: 15px;
              .editprofile_view {
                .profile_upload {
                  margin-bottom: 30px;
                  .profile {
                    margin-right: 15px;
                    > img {
                      height: 60px;
                      width: 60px;
                    }
                    .upload_btn {
                      width: 26px;
                      height: 26px;
                      > img {
                        max-height: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.doctorappointment_modal {
          &.doctorappointmentreject_modal {
            .modal-content {
              .modal-body {
                padding: 0px 15px 20px;
              }
            }
          }
          .modal-content {
            .modal-body {
              padding: 0px 15px 20px;
            }
          }
        }
      }
    }
  }
}

.breadcrumb_sec {
  padding: 20px 0;
  &.light_gray {
    background-color: $lightgray;
  }

  @include media-breakpoint-down(md) {
    padding: 15px 0;
  }
  @include media-breakpoint-down(sm) {
    padding: 10px 0;
  }
}

.slider_sec {
  &.users_say_sec {
    .heading {
      padding-top: 30px;
      margin-bottom: 50px;
    }
    .slider_view {
      .slick-slider {
        .slick-list {
          margin: 0 -10px;
          padding: 42px 0 0;
        }
        .slick-arrow {
          &.slick-prev {
            left: -65px;
          }
          &.slick-next {
            right: -65px;
          }
        }
      }
      .slide_item {
        .content {
          background-color: $white;
          text-align: center;
          position: relative;
          padding: 51px 30px 35px;
          border-radius: 4px;
          margin: 0 10px;
          min-height: 287px;
          img {
            height: 84px;
            width: 84px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            transform: translate(0, -50%);
            display: inline-block;
            z-index: 1;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
          h5 {
            font-size: 30px;
            color: $shark;
            font-family: $medium_font;
            margin-bottom: 7px;
            line-height: normal;
          }
          span {
            letter-spacing: 0.32px;
            font-size: 16px;
            color: $sslogray;
            display: block;
            line-height: normal;
          }
          p {
            margin-top: 30px;
            font-size: 18px;
            letter-spacing: 0.36px;
            margin-bottom: 0;
            line-height: 28px;
            color: $sslogray;
          }
        }
      }
    }
  }

  @include desktop-1680 {
    &.users_say_sec {
      .slider_view {
        .slick-slider {
          .slick-arrow {
            &.slick-prev {
              left: -75px;
            }
            &.slick-next {
              right: -75px;
            }
          }
        }
      }
    }
  }
  @include desktop-1600 {
    &.users_say_sec {
      .slider_view {
        .slick-slider {
          .slick-arrow {
            &.slick-prev {
              left: -55px;
            }
            &.slick-next {
              right: -55px;
            }
          }
        }
        .slide_item {
          .content {
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  @include desktop-1440 {
    &.users_say_sec {
      .slider_view {
        .slick-slider {
          .slick-list {
            padding: 37px 0 0;
          }
        }
        .slide_item {
          .content {
            padding: 45px 25px 25px;
            min-height: 300px;
            img {
              height: 74px;
              width: 74px;
            }
            h5 {
              font-size: 24px;
            }
            span {
              font-size: 15px;
            }
            p {
              margin-top: 25px;
              font-size: 15px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  @include desktop-1399 {
    &.users_say_sec {
      .slider_view {
        .slick-slider {
          .slick-arrow {
            &.slick-prev {
              left: -60px;
            }
            &.slick-next {
              right: -60px;
            }
          }
        }
      }
    }
  }
  @include desktop-1280 {
    &.users_say_sec {
      .slider_view {
        .slick-slider {
          .slick-arrow {
            &.slick-prev {
              left: -42px;
            }
            &.slick-next {
              right: -42px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &.users_say_sec {
      .heading {
        margin-bottom: 40px;
      }
      .slider_view {
        .slick-slider {
          .slick-list {
            padding: 33px 0 0;
          }
          .slick-arrow {
            &.slick-prev {
              left: -35px;
            }
            &.slick-next {
              right: -35px;
            }
          }
        }
        .slide_item {
          .content {
            padding: 40px 20px 20px;
            min-height: 245px;
            img {
              height: 64px;
              width: 64px;
            }
            h5 {
              font-size: 20px;
            }
            span {
              font-size: 14px;
            }
            p {
              margin-top: 20px;
              font-size: 14px;
              line-height: 23px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    &.users_say_sec {
      .heading {
        margin-bottom: 30px;
      }
      .slider_view {
        .slick-slider {
          .slick-arrow {
            top: -42px;
            &.slick-prev {
              left: auto;
              right: 30px;
            }
            &.slick-next {
              right: 0;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &.users_say_sec {
      .slider_view {
        .slick-slider {
          .slick-arrow {
            &.slick-prev {
              right: 25px;
            }
          }
        }
      }
    }
  }
}

.rating_view_concept {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  input {
    display: none;
  }
  label {
    color: $alto;
    cursor: pointer;
    margin-bottom: 0;
    line-height: normal;
    margin-right: 12px;
    &:before {
      content: "\f005";
      font-size: 30px;
      font-family: "FontAwesome";
      display: inline-block;
    }
  }
  > input {
    &:checked {
      ~ label {
        color: $weborange;
      }
    }
    &:hover {
      ~ label {
        color: $weborange;
      }
    }
  }
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 4px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $sslogray;
    box-shadow: inset 0 0 6px $sslogray;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $themeblue;
    border-radius: 10px;
  }
}

.slots {
  padding: 0 32px;
  &.doctor_appointment_slides {
    padding: 0;
    .slots_date {
      display: flex;
      margin-bottom: 35px;
      align-items: center;
      .slots_date_pick {
        display: inline-flex;
        input {
          background-color: $white;
          border: none;
          height: 46px;
          padding: 13px;
          border-radius: 4px;
          color: $sslogray;
          font-size: 16px;
          &:focus {
            @include no-outline();
          }
          &::-webkit-calendar-picker-indicator {
            background: url("../../images/calendar_icon.png") no-repeat;
          }
        }
      }
      .controls {
        width: calc(100% - 195px);
        margin-left: 20px;
        margin-bottom: 0;
        .slick-list {
          .slick-track {
            margin: 0;
          }
        }
      }
    }
    .controls {
      padding: 0 32px;
      margin-bottom: 35px;
    }
    .slick-arrow {
      &.slick-prev {
        left: 0;
      }
      &.slick-next {
        right: 0;
      }
    }
    .content_slider {
      margin-bottom: 0px;
      .doctor_box {
        margin-bottom: 25px;
        .media-body {
          .heading_like {
            &.patient_view {
              margin-bottom: 7px;
              cursor: pointer;
              h3 {
                max-width: 140px;
              }
              span {
                color: $themeblue;
                display: inline-flex;
                font-size: 14px;
                align-items: center;
                line-height: normal;
                > img {
                  margin-right: 5px;
                }
              }
            }
          }
          .doctor_calling_system {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .left_view {
              line-height: normal;
              .btn {
                font-size: 16px;
                font-family: $medium_font;
                color: $deepsea;
                + .btn {
                  margin-left: 24px;
                  color: $carnation;
                }
              }
            }
          }
        }
      }
    }
  }
  .slick-arrow {
    &.slick-prev,
    &.slick-next {
      width: 13px;
      height: 23px;
      display: flex;
    }
    &.slick-prev {
      left: -32px;
    }
    &.slick-next {
      right: -32px;
    }
  }
  .controls {
    margin-bottom: 22px;
    .slick-list {
      .slick-slide {
        &.slick-current {
          .tab_item {
            color: $themeblue;
            font-family: $medium_font;
          }
        }
        .tab_item {
          font-size: 14px;
          color: $sslogray;
          margin-right: 15px;
          text-align: center;
          cursor: pointer;
        }
        &:first-child {
          .tab_item {
            text-align: left;
          }
        }
      }
    }
  }
  .content_slider {
    margin-bottom: 50px;
    .tab_content {
      ul {
        margin-bottom: 0;
        li {
          font-size: 14px;
          color: $themeblue;
          font-family: $medium_font;
          border-radius: 4px;
          background-color: $aquahaze;
          line-height: normal;
          cursor: pointer;
          width: 90px;
          padding: 10px;
          margin-top: 10px;
          text-align: center;
          &.active {
            background-color: $themeblue;
            color: $white;
          }
          &:not(:last-child) {
            width: 90px;
            padding: 10px;
            margin-top: 10px;
            text-align: center;
          }
        }
      }
    }
  }
  .btn_primary {
    font-size: 14px;
    padding: 12px 20px;
    font-family: $medium_font;
  }

  @include desktop-1680 {
    &.doctor_appointment_slides {
      .slots_date {
        .slots_date_pick {
          input {
            height: 40px;
            padding: 10px;
            font-size: 14px;
          }
        }
        .controls {
          width: calc(100% - 173px);
          margin-left: 15px;
        }
      }
      .content_slider {
        .doctor_box {
          padding: 15px;
          .media-body {
            .heading_like {
              &.patient_view {
                h3 {
                  max-width: 120px;
                }
                span {
                  font-size: 13px;
                }
              }
            }
            .doctor_calling_system {
              .left_view {
                .btn {
                  font-size: 14px;
                  + .btn {
                    margin-left: 20px;
                  }
                }
              }
              .call_btns {
                .btn {
                  > img {
                    max-height: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include laptop-screen-1200 {
    &.doctor_appointment_slides {
      .content_slider {
        .col-xl-4 {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
  @include desktop-1399 {
    .content_slider {
      .tab_content {
        ul {
          li {
            font-size: 13px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 0 25px;
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        width: 8px;
        height: 16px;
      }
      &.slick-prev {
        left: -25px;
      }
      &.slick-next {
        right: -25px;
      }
    }
    .controls {
      .slick-list {
        .slick-slide {
          .tab_item {
            font-size: 13px;
            margin-right: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .content_slider {
      margin-bottom: 30px;
      .tab_content {
        ul {
          li {
            font-size: 12px;
            padding: 10px;
            &:not(:last-child) {
              margin-right: 7px;
              margin-bottom: 7px;
            }
          }
        }
      }
    }
    .btn_primary {
      padding: 12px 12px;
    }
  }
  @include media-breakpoint-down(sm) {
    &.doctor_appointment_slides {
      .slots_date {
        margin-bottom: 20px;
      }
      .controls {
        padding: 0 20px;
        margin-bottom: 20px;
      }
      .content_slider {
        .doctor_box {
          margin-bottom: 15px;
          .media-body {
            .heading_like {
              &.patient_view {
                h3 {
                  max-width: 250px;
                }
                span {
                  font-size: 12px;
                }
              }
            }
            .doctor_calling_system {
              .left_view {
                .btn {
                  + .btn {
                    margin-left: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(xs) {
    &.doctor_appointment_slides {
      .content_slider {
        .doctor_box {
          .media-body {
            .heading_like {
              &.patient_view {
                h3 {
                  max-width: 110px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.common_picker {
  width: 100%;
  .select__control {
    border: none;
    border-bottom: 1px solid $alto;
    background-color: $white;
    border-radius: 0;
    min-height: 30px;
    @include box-shadow(none);
    padding: 0px 0px 2px;
    &:focus,
    &:hover {
      @include no-outline();
      border-color: $alto;
    }
    .select__value-container {
      padding: 0;
      line-height: 20px;
      .select__placeholder,
      .select__single-value {
        margin: 0;
      }
      .select__placeholder {
        color: $sslogray;
        font-size: 16px;
      }
      .select__single-value {
        color: $mako;
        font-size: 18px;
      }
      .select__input {
        color: $mako;
      }
    }
    .select__indicators {
      &:hover {
        color: $mako;
      }
      .select__indicator-separator {
        display: none;
      }
      .select__indicator {
        padding: 0;
        color: $mako;
      }
    }
  }
  .select__menu {
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: $white;
    padding: 0;
    @include box-shadow(0 1px 15px rgba($black, 0.03));
    .select__menu-list {
      padding: 0;
      .select__option {
        font-size: 16px;
        color: $mako;
        cursor: pointer;
        &.select__option--is-focused {
          background-color: transparent;
        }
        &.select__option--is-selected {
          background-color: $themeblue;
          color: $white;
        }
        &:hover {
          &.select__option--is-focused {
            background-color: transparent;
          }
          &.select__option--is-selected {
            background-color: $themeblue;
            color: $white;
          }
        }
      }
      .select__menu-notice {
        font-size: 16px;
        color: $mako;
      }
    }
  }

  @include desktop-1680 {
    .select__control {
      .select__value-container {
        .select__placeholder {
          font-size: 15px;
        }
        .select__single-value {
          font-size: 15px;
        }
      }
    }
    .select__menu {
      .select__menu-list {
        .select__option {
          font-size: 15px;
        }
        .select__menu-notice {
          font-size: 15px;
        }
      }
    }
  }
  @include desktop-1440 {
    .select__control {
      .select__value-container {
        .select__placeholder {
          font-size: 14px;
        }
        .select__single-value {
          font-size: 14px;
        }
      }
    }
    .select__menu {
      .select__menu-list {
        .select__option {
          font-size: 14px;
        }
        .select__menu-notice {
          font-size: 14px;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .select__control {
      .select__value-container {
        .select__placeholder {
          font-size: 13px;
        }
        .select__single-value {
          font-size: 13px;
        }
      }
    }
    .select__menu {
      .select__menu-list {
        .select__option {
          font-size: 13px;
        }
        .select__menu-notice {
          font-size: 13px;
        }
      }
    }
  }
}

//Prescription Box
.prescription_box {
  margin-bottom: 25px;
  .top_view {
    background-color: $aquahaze;
    padding: 15px 15px 10px;
    border-radius: 6px 6px 0px 0px;
    height:80px;
    h6 {
      font-size: 15px;
      font-family: $medium_font;
      margin-bottom: 6px;
    }
    p {
      font-size: 13px;
      color: $sslogray;
      margin-bottom: 0;
      line-height: 18px;
      overflow: hidden;
      display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    }
  }
  .bottom_view {
    background-color: $aquahaze;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid $white;
    border-radius: 0px 0px 6px 6px;
    span {
      color: $shark;
      align-items: baseline;
      display: inline-flex;
      font-size: 13px;
      &:not(:last-child) {
        border-right: 1px solid $white;
        padding-right: 15px;
        margin-right: 16px;
      }
      b {
        font-weight: normal;
        font-family: $medium_font;
        margin-right: 4px;
      }
    }
  }

  @include desktop-1680 {
    .bottom_view {
      white-space: nowrap;
      overflow: hidden;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $sslogray;
        box-shadow: inset 0 0 6px $sslogray;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $themeblue;
        border-radius: 10px;
      }
    }
  }
}

//Editor Box
.editor_wrapper {
  border: 1px solid $alto;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
  min-height: 215px;
  justify-content: space-between;
  .editor_toolbar {
    padding: 0;
    border-radius: 0;
    border: none;
    margin: 0;
    font-size: 13px;
    color: inherit;
    > div {
      margin-bottom: 0;
      > div {
        height: auto;
        border: none;
        &:hover,
        &:focus,
        &:active,
        &.rdw-option-active {
          @include box-shadow(none !important);
          background-color: rgba($sslogray, 0.4);
        }
        &.tools {
          height: 27px;
          a {
            color: $sslogray;
          }
        }
      }
      &:not(:last-child) {
        border-right: 2px solid $alto;
        padding-right: 20px;
        margin-right: 20px;
      }
    }
  }
  .editor_inner {
    .public-DraftEditorPlaceholder-inner {
      font-size: 14px;
      color: $sslogray;
    }
    .public-DraftStyleDefault-block {
      margin-top: 0;
    }
  }
}

.doctor_app_tabs_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}