@charset "UTF-8";
@font-face {
  font-family: "GTWalsheimPro-Regular";
  font-weight: "normal";
  font-style: "normal";
  src: url("../../fonts/GTWalsheimPro-Regular.eot");
  src: url("../../fonts/GTWalsheimPro-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/GTWalsheimPro-Regular.woff") format("woff"), url("../../fonts/GTWalsheimPro-Regular.ttf") format("truetype"), url("../../fonts/GTWalsheimPro-Regular.svg#GTWalsheimPro-Regular") format("svg");
}
@font-face {
  font-family: "GTWalsheimPro-Medium";
  font-weight: "normal";
  font-style: "normal";
  src: url("../../fonts/GTWalsheimPro-Medium.eot");
  src: url("../../fonts/GTWalsheimPro-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/GTWalsheimPro-Medium.woff") format("woff"), url("../../fonts/GTWalsheimPro-Medium.ttf") format("truetype"), url("../../fonts/GTWalsheimPro-Medium.svg#GTWalsheimPro-Medium") format("svg");
}
@font-face {
  font-family: "GTWalsheimPro-Bold";
  font-weight: "normal";
  font-style: "normal";
  src: url("../../fonts/GTWalsheimPro-Bold.eot");
  src: url("../../fonts/GTWalsheimPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/GTWalsheimPro-Bold.woff") format("woff"), url("../../fonts/GTWalsheimPro-Bold.ttf") format("truetype"), url("../../fonts/GTWalsheimPro-Bold.svg#GTWalsheimPro-Bold") format("svg");
}
@font-face {
  font-family: "GTWalsheimPro-Light";
  font-weight: "normal";
  font-style: "normal";
  src: url("../../fonts/GTWalsheimPro-Light.eot");
  src: url("../../fonts/GTWalsheimPro-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/GTWalsheimPro-Light.woff") format("woff"), url("../../fonts/GTWalsheimPro-Light.ttf") format("truetype"), url("../../fonts/GTWalsheimPro-Light.svg#GTWalsheimPro-Light") format("svg");
}
body,
html {
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-family: "GTWalsheimPro-Regular";
}

a {
  -webkit-transition: linear 0.3s;
  -o-transition: linear 0.3s;
  transition: linear 0.3s;
  outline: none;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1180px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}
@media (min-width: 1681px) {
  .container {
    max-width: 1710px;
  }
}
@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

label.underline_label {
  font-size: 14px;
  color: #8B8F95;
  margin-bottom: 0;
  display: block;
  line-height: normal;
}
@media (max-width: 1399px) {
  label.underline_label {
    font-size: 13px;
  }
}

.orange_clr {
  color: #F67054;
}

.form-control {
  font-size: 14px;
  height: 50px;
  border-radius: 5px;
  padding: 8px 12px;
  border: 1px solid #DBDBDB;
  background-color: #ffffff;
  width: 100%;
  color: #3E454F;
  font-family: "GTWalsheimPro-Regular";
}
.form-control:focus, .form-control:hover, .form-control:active {
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  -ms-outline: none;
  -o-outline: none;
  box-shadow: none !important;
  border: 1px solid #DBDBDB;
  background-color: #ffffff;
}
.form-control.underline_input {
  height: auto;
  border: none;
  border-bottom: solid 1px #DBDBDB;
  border-radius: 0;
  font-size: 18px;
  padding: 5px 0 5px;
  line-height: normal;
}
.form-control.underline_input:focus, .form-control.underline_input:hover, .form-control.underline_input:active {
  border-bottom: 1px solid #DBDBDB;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #ffffff;
  cursor: auto;
}
.form-control::-webkit-input-placeholder {
  color: #8B8F95 !important;
}
.form-control::-moz-placeholder {
  color: #8B8F95 !important;
}
.form-control::-ms-input-placeholder {
  color: #8B8F95 !important;
}
.form-control::-moz-placeholder {
  color: #8B8F95 !important;
}
@media (max-width: 1680px) {
  .form-control.underline_input {
    font-size: 15px;
  }
}
@media (max-width: 1399px) {
  .form-control {
    height: 45px;
  }
  .form-control.underline_input {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .form-control {
    height: 40px;
    font-size: 13px;
  }
  .form-control.underline_input {
    font-size: 13px;
  }
}

.medium_font {
  font-family: "GTWalsheimPro-Medium";
}

.btn {
  padding: 15px 15px;
  cursor: pointer;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  line-height: normal;
  font-weight: normal;
}
.btn.min_180 {
  min-width: 180px;
}
.btn.btn_primary {
  color: #ffffff;
  background-color: #324688;
}
.btn.btn_primary:hover, .btn.btn_primary:focus, .btn.btn_primary:active {
  color: #ffffff;
  background-color: #324688;
}
.btn.accept_btn {
  color: #ffffff;
  background-color: #038B57;
}
.btn.accept_btn:hover, .btn.accept_btn:focus, .btn.accept_btn:active {
  color: #ffffff;
  background-color: #038B57;
}
.btn.reject_btn {
  color: #ffffff;
  background-color: #F67054;
}
.btn.reject_btn:hover, .btn.reject_btn:focus, .btn.reject_btn:active {
  color: #ffffff;
  background-color: #F67054;
}
.btn.disabled, .btn:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
.btn:hover, .btn:focus {
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  -ms-outline: none;
  -o-outline: none;
  box-shadow: none;
}
@media (max-width: 1600px) {
  .btn {
    font-size: 16px;
  }
  .btn.min_180 {
    min-width: 150px;
  }
}
@media (max-width: 1399px) {
  .btn {
    padding: 12px 15px;
    font-size: 15px;
  }
  .btn.min_180 {
    min-width: 140px;
  }
}
@media (max-width: 1199px) {
  .btn {
    font-size: 14px;
  }
  .btn.min_180 {
    min-width: 130px;
  }
}
@media (max-width: 991px) {
  .btn.min_180 {
    min-width: 120px;
  }
}
@media (max-width: 767px) {
  .btn {
    padding: 12px 12px;
  }
  .btn.min_180 {
    min-width: 110px;
  }
}

.child-content {
  min-height: calc(100vh - 477px);
}

.invalid_error .input-group-prepend .input-group-text {
  border-color: #F67054 !important;
}
.invalid_error .form-control {
  border-color: #F67054 !important;
}

.pass_hint,
.success,
.error {
  font-size: 13px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 0;
  position: absolute;
  bottom: calc(100% - 75px);
  line-height: normal;
}
@media (max-width: 1399px) {
  .pass_hint,
  .success,
  .error {
    bottom: calc(100% - 63px);
    margin-top: 3px;
  }
}
@media (max-width: 1199px) {
  .pass_hint,
  .success,
  .error {
    font-size: 12px;
    bottom: calc(100% - 57px);
  }
}
@media (max-width: 767px) {
  .pass_hint,
  .success,
  .error {
    font-size: 11px;
    position: relative;
    bottom: 0;
  }
}

.pass_hint {
  color: #3E454F;
}

.error {
  color: #F67054;
}

.success {
  color: #038B57;
}

.pos_unset {
  position: inherit;
  bottom: 0;
}

.slick-arrow.slick-prev, .slick-arrow.slick-next {
  width: auto;
  height: auto;
  z-index: 1;
}
.slick-arrow.slick-prev:before, .slick-arrow.slick-next:before {
  opacity: 1;
  content: none;
}
@media (max-width: 1399px) {
  .slick-arrow.slick-prev > img, .slick-arrow.slick-next > img {
    max-width: 30px;
  }
}
@media (max-width: 1199px) {
  .slick-arrow.slick-prev > img, .slick-arrow.slick-next > img {
    max-width: 25px;
  }
}
@media (max-width: 767px) {
  .slick-arrow.slick-prev > img, .slick-arrow.slick-next > img {
    max-width: 20px;
  }
}

.common_selectpicker {
  width: 100%;
}
.common_selectpicker .select__control {
  border: 1px solid #f6f8fa;
  background-color: #ffffff;
  border-radius: 0;
  min-height: 40px;
  box-shadow: none;
  padding: 6px 13px;
}
.common_selectpicker .select__control:focus, .common_selectpicker .select__control:hover {
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  -ms-outline: none;
  -o-outline: none;
  border-color: #f6f8fa;
}
.common_selectpicker .select__control .select__value-container {
  padding: 0;
  line-height: 18px;
}
.common_selectpicker .select__control .select__value-container .select__placeholder {
  margin: 0;
  color: #7C8188;
  font-size: 14px;
}
.common_selectpicker .select__control .select__value-container .select__input {
  color: #7C8188;
}
.common_selectpicker .select__control .select__indicators:hover {
  color: #3E454F !important;
}
.common_selectpicker .select__control .select__indicators .select__indicator-separator {
  display: none;
}
.common_selectpicker .select__control .select__indicators .select__indicator {
  padding: 0;
  color: #3E454F !important;
}
.common_selectpicker .select__menu {
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: #ffffff;
  padding: 0;
  box-shadow: none;
}
.common_selectpicker .select__menu .select__menu-list {
  padding: 0;
}
.common_selectpicker .select__menu .select__menu-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
}
.common_selectpicker .select__menu .select__menu-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #8B8F95;
  box-shadow: inset 0 0 6px #8B8F95;
  border-radius: 10px;
}
.common_selectpicker .select__menu .select__menu-list::-webkit-scrollbar-thumb {
  background-color: #324688;
  border-radius: 10px;
}
.common_selectpicker .select__menu .select__menu-list .select__option {
  font-size: 16px;
  color: #1C213A;
}
.common_selectpicker .select__menu .select__menu-list .select__option.select__option--is-focused {
  background-color: transparent;
}
.common_selectpicker .select__menu .select__menu-list .select__option:hover.select__option--is-focused {
  background-color: transparent;
}
.common_selectpicker .select__menu .select__menu-list .select__option p {
  cursor: pointer;
}
.common_selectpicker .select__menu .select__menu-list .select__menu-notice {
  font-size: 14px;
  color: #000000;
}
@media (max-width: 1199px) {
  .common_selectpicker .select__control .select__value-container .select__placeholder {
    font-size: 13px;
  }
  .common_selectpicker .select__menu .select__menu-list .select__option {
    font-size: 13px;
  }
  .common_selectpicker .select__menu .select__menu-list .select__menu-notice {
    font-size: 13px;
  }
}

.custom-checkbox.right {
  padding-left: 0;
  padding-right: 30px;
}
.custom-checkbox.right:not(:last-child) {
  margin-bottom: 22px;
}
.custom-checkbox.right .custom-control-input {
  right: 0;
  left: auto;
  width: 20px;
  height: 20px;
  top: 2px;
}
.custom-checkbox.right .custom-control-input:checked ~ .custom-control-label {
  color: #3E454F;
  font-family: "GTWalsheimPro-Medium";
}
.custom-checkbox.right .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #324688;
  background-color: #324688;
}
.custom-checkbox.right .custom-control-input:checked ~ .custom-control-label:after {
  background: url("../../images/right_sign.svg") no-repeat center center;
}
.custom-checkbox.right .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none;
}
.custom-checkbox.right .custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #979797;
}
.custom-checkbox.right .custom-control-label {
  width: 100%;
  color: #8B8F95;
  cursor: pointer;
  font-size: 16px;
}
.custom-checkbox.right .custom-control-label:before, .custom-checkbox.right .custom-control-label:after {
  left: auto;
  right: -30px;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.custom-checkbox.right .custom-control-label:before {
  border-radius: 3px;
  border-color: #979797;
}
@media (max-width: 1680px) {
  .custom-checkbox.right:not(:last-child) {
    margin-bottom: 15px;
  }
  .custom-checkbox.right .custom-control-input {
    width: 18px;
    height: 18px;
  }
  .custom-checkbox.right .custom-control-label {
    font-size: 14px;
  }
  .custom-checkbox.right .custom-control-label:before, .custom-checkbox.right .custom-control-label:after {
    width: 18px;
    height: 18px;
  }
}
@media (max-width: 991px) {
  .custom-checkbox.right:not(:last-child) {
    margin-bottom: 12px;
  }
  .custom-checkbox.right .custom-control-label {
    font-size: 13px;
  }
}

.doctor_box {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 20px;
}
.doctor_box .media-left {
  margin-right: 10px;
}
.doctor_box .media-left > img {
  min-width: 65px;
  width: 65px;
  height: 65px;
  border-radius: 4px;
}
.doctor_box .media-body {
  padding: 0;
}
.doctor_box .media-body .rating_view {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  line-height: normal;
  min-height: 18px;
}
.doctor_box .media-body .rating_view .left {
  display: flex;
  align-items: center;
}
.doctor_box .media-body .rating_view .left span {
  font-size: 14px;
  color: #1C1D1F;
  font-family: "GTWalsheimPro-Medium";
  display: inline-block;
  margin-right: 6px;
  line-height: normal;
}
.doctor_box .media-body .rating_view .right {
  margin-left: 8px;
}
.doctor_box .media-body .rating_view .right span {
  font-size: 14px;
  color: #8B8F95;
  font-family: "GTWalsheimPro-Medium";
  display: inline-block;
  line-height: normal;
}
.doctor_box .media-body .heading_like {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.doctor_box .media-body .heading_like h3 {
  font-size: 18px;
  color: #1C1D1F;
  margin-bottom: 0;
  font-family: "GTWalsheimPro-Medium";
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 220px;
  display: inline-block;
  overflow: hidden;
  line-height: normal;
}
.doctor_box .media-body .heading_like .badge {
  color: #000;
  font-size: 11px;
  padding: 5px 14px;
  background: #ffd5d1 !important;
  font-weight: 500;
}
.doctor_box .media-body .heading_like .btn {
  font-size: 22px;
  color: #DBDBDB;
  border-radius: 0;
  line-height: normal;
  display: inline-block;
}
.doctor_box .media-body .heading_like .btn.fav_doc {
  color: #F67054;
}
.doctor_box .media-body .heading_like .btn + .btn {
  margin-left: 17px;
}
.doctor_box .media-body .fees_view {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.doctor_box .media-body .fees_view .degree {
  margin-bottom: 0;
  font-size: 14px;
  color: #8B8F95;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;
  overflow: hidden;
}
.doctor_box .media-body .fees_view .degree .on_off_status {
  position: relative;
}
.doctor_box .media-body .fees_view .degree .on_off_status.active p {
  color: #11CA71;
}
.doctor_box .media-body .fees_view .degree .on_off_status.active p:before {
  background-color: #11CA71;
}
.doctor_box .media-body .fees_view .degree .on_off_status p {
  font-size: 12px;
  font-family: "GTWalsheimPro-Medium";
  margin-bottom: 8px;
  padding-left: 10px;
}
.doctor_box .media-body .fees_view .degree .on_off_status p:before {
  content: "";
  height: 7.5px;
  width: 7.5px;
  background-color: transparent;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
.doctor_box .media-body .fees_view .badge {
  color: #000;
  font-size: 11px;
  padding: 5px 14px;
  background: #ffd5d1 !important;
  font-weight: 500;
}
.doctor_box .media-body .fees_view span {
  font-size: 14px;
  color: #324688;
  font-family: "GTWalsheimPro-Medium";
  display: inline-block;
  line-height: normal;
}
.doctor_box .media-body .language {
  font-size: 14px;
  color: #324688;
  margin-bottom: 8px;
  line-height: normal;
  min-height: 18px;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.doctor_box .media-body .availability {
  line-height: normal;
  font-size: 12px;
  font-family: "GTWalsheimPro-Medium";
  color: #E88A0C;
  margin-bottom: 20px;
}
.doctor_box .media-body .btn_primary {
  font-size: 14px;
  padding: 12px 21px;
}
.doctor_box.doctor_upcomming_box .media-body .fees_view {
  margin-bottom: 5px;
}
.doctor_box.doctor_upcomming_box .media-body .doctor_calling_system .call_btns {
  position: absolute;
  right: 0;
}
@media (max-width: 1680px) {
  .doctor_box .media-left > img {
    min-width: 60px;
    width: 60px;
    height: 60px;
  }
  .doctor_box .media-body .heading_like h3 {
    font-size: 16px;
    max-width: 180px;
  }
  .doctor_box .media-body .heading_like .btn {
    font-size: 20px;
  }
  .doctor_box .media-body .fees_view {
    margin-bottom: 9px;
  }
  .doctor_box .media-body .fees_view .degree {
    max-width: 140px;
  }
  .doctor_box .media-body .fees_view .degree .on_off_status p {
    font-size: 12px;
    margin-bottom: 8px;
    padding-left: 10px;
  }
  .doctor_box .media-body .availability {
    margin-bottom: 15px;
  }
  .doctor_box .media-body .btn_primary {
    padding: 12px 15px;
  }
}
@media (max-width: 1399px) {
  .doctor_box {
    padding: 15px;
  }
}
@media (max-width: 1199px) {
  .doctor_box .media-left > img {
    min-width: 55px;
    width: 55px;
    height: 55px;
  }
  .doctor_box .media-body .heading_like h3 {
    font-size: 14px;
    max-width: 140px;
  }
  .doctor_box .media-body .heading_like .btn {
    font-size: 18px;
  }
  .doctor_box .media-body .heading_like .btn.share {
    font-size: inherit;
  }
  .doctor_box .media-body .heading_like .btn > img {
    max-height: 17px;
  }
  .doctor_box .media-body .heading_like .btn + .btn {
    margin-left: 12px;
  }
  .doctor_box .media-body .fees_view .degree {
    font-size: 13px;
    max-width: 110px;
  }
  .doctor_box .media-body .fees_view .degree .on_off_status p {
    font-size: 12px;
    margin-bottom: 8px;
    padding-left: 10px;
  }
  .doctor_box .media-body .fees_view span {
    font-size: 13px;
  }
  .doctor_box .media-body .language {
    font-size: 13px;
  }
  .doctor_box .media-body .btn_primary {
    padding: 10px 12px;
  }
}
@media (max-width: 991px) {
  .doctor_box .media-body .heading_like h3 {
    max-width: 130px;
  }
}

.load_more {
  text-align: center;
  margin-top: 20px;
}
.load_more .btn {
  font-size: 26px;
  color: #3E454F;
  font-family: "GTWalsheimPro-Medium";
  border-radius: 0;
}
@media (max-width: 1680px) {
  .load_more .btn {
    font-size: 24px;
  }
}
@media (max-width: 1399px) {
  .load_more .btn {
    font-size: 22px;
  }
}
@media (max-width: 1199px) {
  .load_more {
    margin-top: 15px;
  }
  .load_more .btn {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .load_more {
    margin-top: 10px;
  }
  .load_more .btn {
    font-size: 16px;
  }
}

.modal-backdrop {
  background-color: rgba(62, 69, 79, 0.8);
}
.modal-backdrop.show {
  opacity: 1;
}

@media (min-width: 576px) {
  .modal .modal-dialog.custom_modal.modal_md {
    max-width: 600px;
  }
}
@media (min-width: 576px) {
  .modal .modal-dialog.custom_modal.modal_sm {
    max-width: 400px;
  }
}
.modal .modal-dialog.custom_modal.common_close_btn .modal-content .modal-header .close {
  display: block;
  padding: 0;
  right: 20px;
  top: 25px;
  margin: 0;
  position: absolute;
  height: 13px;
  width: 13px;
  opacity: 1;
  z-index: 11;
  background: url("../../images/close_icon.svg") no-repeat;
}
.modal .modal-dialog.custom_modal.common_close_btn .modal-content .modal-header .close:focus {
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  -ms-outline: none;
  -o-outline: none;
}
.modal .modal-dialog.custom_modal.common_close_btn .modal-content .modal-header .close span {
  display: none;
}
.modal .modal-dialog.custom_modal .modal-content {
  border: none;
  border-radius: 10px;
}
.modal .modal-dialog.custom_modal .modal-content .modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 38px 30px 22px;
  border-bottom: 1px solid #DBDBDB;
  justify-content: center;
}
.modal .modal-dialog.custom_modal .modal-content .modal-header .close {
  display: none;
}
.modal .modal-dialog.custom_modal .modal-content .modal-header .modal-title {
  font-size: 18px;
  font-family: "GTWalsheimPro-Medium";
  color: #3E454F;
  line-height: normal;
}
.modal .modal-dialog.custom_modal .modal-content .modal-body {
  padding: 15px 30px;
}
.modal .modal-dialog.custom_modal .modal-content .modal-footer {
  padding: 15px;
  border-top: 1px solid #DBDBDB;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.modal .modal-dialog.custom_modal .modal-content .modal-footer > * {
  margin: 0;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body::-webkit-scrollbar {
  width: 3px;
  height: 4px;
  border-radius: 10px;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #8B8F95;
  box-shadow: inset 0 0 6px #8B8F95;
  border-radius: 10px;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body::-webkit-scrollbar-thumb {
  background-color: #324688;
  border-radius: 10px;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio {
  padding-left: 0;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio:not(:last-child) {
  margin-bottom: 15px;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio .custom-control-input:checked ~ .custom-control-label {
  color: #1C1D1F;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #E88A0C;
  background-color: transparent;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio .custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #979797;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio .custom-control-label {
  display: block;
  padding: 15px 15px;
  color: #1C1D1F;
  font-family: "GTWalsheimPro-Medium";
  font-size: 14px;
  cursor: pointer;
  padding-left: 55px;
  text-align: left;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio .custom-control-label:before, .modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio .custom-control-label:after {
  width: 100%;
  height: 52px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio .custom-control-label:before {
  border-radius: 12px;
  background-color: transparent;
  border: 1px solid #DBDBDB;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio .custom-control-label:after {
  background: none;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .custom-radio > img {
  height: 29px;
  width: 29px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}
.modal .modal-dialog.custom_modal.specialities_modal .modal-content .modal-body .btn {
  margin-top: 30px;
}
.modal .modal-dialog.custom_modal.change_password_modal .modal-content .modal-body {
  padding: 20px 40px;
  text-align: left;
}
.modal .modal-dialog.custom_modal.change_password_modal .modal-content .modal-body .changepw_info {
  color: #3E454F;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 19px;
}
.modal .modal-dialog.custom_modal.change_password_modal .modal-content .modal-body .form-group {
  margin-bottom: 30px;
}
.modal .modal-dialog.custom_modal.change_password_modal .modal-content .modal-body .btn {
  font-size: 16px;
  font-family: "GTWalsheimPro-Medium";
}
.modal .modal-dialog.custom_modal.change_password_success_modal .modal-content .modal-body {
  padding: 35px;
  text-align: center;
}
.modal .modal-dialog.custom_modal.change_password_success_modal .modal-content .modal-body .success_content > img {
  margin-bottom: 40px;
}
.modal .modal-dialog.custom_modal.change_password_success_modal .modal-content .modal-body .success_content h2 {
  font-size: 30px;
  font-family: "GTWalsheimPro-Medium";
  color: #3E454F;
  line-height: normal;
  margin-bottom: 17px;
}
.modal .modal-dialog.custom_modal.change_password_success_modal .modal-content .modal-body .success_content p {
  font-size: 16px;
  line-height: normal;
  color: #8B8F95;
  margin-bottom: 42px;
}
.modal .modal-dialog.custom_modal.change_password_success_modal .modal-content .modal-body .success_content .btn {
  font-size: 16px;
  min-height: 50px;
}
.modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body {
  padding: 40px;
}
.modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
}
.modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .profile {
  display: inline-block;
  position: relative;
  margin-right: 17px;
}
.modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .profile > img {
  height: 109px;
  width: 109px;
  border-radius: 6px;
  object-fit: cover;
}
.modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .profile .upload_btn {
  width: 46px;
  height: 46px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  margin: 0;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .btn {
  font-size: 16px;
  display: inline-flex;
  color: #F67054;
  font-family: "GTWalsheimPro-Medium";
  text-decoration: underline;
}
.modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .btn > img {
  margin-right: 7px;
}
.modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .form-group {
  margin-bottom: 30px;
}
.modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .btn_primary {
  font-size: 16px;
  font-family: "GTWalsheimPro-Bold";
  min-height: 50px;
}
.modal .modal-dialog.custom_modal.wishlist_modal .modal-content .modal-header {
  border-bottom: none;
}
.modal .modal-dialog.custom_modal.wishlist_modal .modal-content .modal-body {
  text-align: center;
  padding: 38px 50px;
}
.modal .modal-dialog.custom_modal.wishlist_modal .modal-content .modal-body p {
  margin-bottom: 0;
  line-height: normal;
  color: #F67054;
  font-size: 16px;
}
.modal .modal-dialog.custom_modal.wishlist_modal .modal-content .modal-footer .btn {
  color: #3E454F;
  font-size: 15px;
  font-family: "GTWalsheimPro-Medium";
}
.modal .modal-dialog.custom_modal.logout_modal .modal-content .modal-header {
  border-bottom: none;
}
.modal .modal-dialog.custom_modal.logout_modal .modal-content .modal-body {
  text-align: center;
  padding: 10px 50px 50px;
}
.modal .modal-dialog.custom_modal.logout_modal .modal-content .modal-body p {
  margin-bottom: 0;
  line-height: normal;
  color: #8B8F95;
  font-size: 16px;
}
.modal .modal-dialog.custom_modal.logout_modal .modal-content .modal-footer {
  display: flex;
  justify-content: space-around;
  padding: 0px;
}
.modal .modal-dialog.custom_modal.logout_modal .modal-content .modal-footer .divider {
  position: relative;
}
.modal .modal-dialog.custom_modal.logout_modal .modal-content .modal-footer .divider:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 25px;
  background-color: #DBDBDB;
  top: 50%;
  transform: translateY(-50%);
}
.modal .modal-dialog.custom_modal.logout_modal .modal-content .modal-footer .btn {
  color: #8B8F95;
  font-size: 15px;
  font-family: "GTWalsheimPro-Medium";
  padding: 13px ​30px;
  width: 50%;
}
.modal .modal-dialog.custom_modal.logout_modal .modal-content .modal-footer .btn ~ .btn {
  color: #3E454F;
}
.modal .modal-dialog.custom_modal.stripe_payment_modal .modal-dialog {
  width: 500px !important;
}
.modal .modal-dialog.custom_modal.stripe_payment_modal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
}
.modal .modal-dialog.custom_modal.stripe_payment_modal .modal-dialog .modal-content .modal-body {
  text-align: center;
  padding: 10px 50px 50px;
}
.modal .modal-dialog.custom_modal.stripe_payment_modal .modal-dialog .modal-content .modal-body p {
  margin-bottom: 0;
  line-height: normal;
  color: #8B8F95;
  font-size: 16px;
}
.modal .modal-dialog.custom_modal.stripe_payment_modal .modal-dialog .modal-content .modal-footer {
  display: flex;
  justify-content: space-around;
  padding: 0px;
}
.modal .modal-dialog.custom_modal.stripe_payment_modal .modal-dialog .modal-content .modal-footer .divider {
  position: relative;
}
.modal .modal-dialog.custom_modal.stripe_payment_modal .modal-dialog .modal-content .modal-footer .divider:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 25px;
  background-color: #DBDBDB;
  top: 50%;
  transform: translateY(-50%);
}
.modal .modal-dialog.custom_modal.stripe_payment_modal .modal-dialog .modal-content .modal-footer .btn {
  color: #8B8F95;
  font-size: 15px;
  font-family: "GTWalsheimPro-Medium";
  padding: 13px ​30px;
  width: 50%;
}
.modal .modal-dialog.custom_modal.stripe_payment_modal .modal-dialog .modal-content .modal-footer .btn ~ .btn {
  color: #3E454F;
}
.modal .modal-dialog.custom_modal.cancel_appointment .modal-content .modal-header {
  border-bottom: none;
}
.modal .modal-dialog.custom_modal.cancel_appointment .modal-content .modal-body {
  text-align: center;
  padding: 10px 50px 50px;
}
.modal .modal-dialog.custom_modal.cancel_appointment .modal-content .modal-body p {
  margin-bottom: 0;
  line-height: normal;
  color: #8B8F95;
  font-size: 16px;
}
.modal .modal-dialog.custom_modal.cancel_appointment .modal-content .modal-footer {
  display: flex;
  justify-content: space-around;
  padding: 0;
}
.modal .modal-dialog.custom_modal.cancel_appointment .modal-content .modal-footer .divider {
  position: relative;
}
.modal .modal-dialog.custom_modal.cancel_appointment .modal-content .modal-footer .divider:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 25px;
  background-color: #DBDBDB;
  top: 50%;
  transform: translateY(-50%);
}
.modal .modal-dialog.custom_modal.cancel_appointment .modal-content .modal-footer .btn {
  color: #8B8F95;
  font-size: 15px;
  font-family: "GTWalsheimPro-Medium";
  width: 50%;
  padding: 13px ​30px;
}
.modal .modal-dialog.custom_modal.cancel_appointment .modal-content .modal-footer .btn ~ .btn {
  color: #3E454F;
}
.modal .modal-dialog.custom_modal.write_review_modal .modal-content .modal-header {
  border-bottom: none;
}
.modal .modal-dialog.custom_modal.write_review_modal .modal-content .modal-body .form-group {
  margin-bottom: 25px;
}
.modal .modal-dialog.custom_modal.write_review_modal .modal-content .modal-body .form-group .underline_label {
  font-size: 16px;
  margin-bottom: 18px;
}
.modal .modal-dialog.custom_modal.write_review_modal .modal-content .modal-body .form-group .form-control {
  padding: 20px 15px;
}
.modal .modal-dialog.custom_modal.write_review_modal .modal-content .modal-footer {
  padding: 18px 15px;
  justify-content: center;
}
.modal .modal-dialog.custom_modal.write_review_modal .modal-content .modal-footer .btn {
  color: #3E454F;
  font-size: 15px;
  font-family: "GTWalsheimPro-Medium";
}
.modal .modal-dialog.custom_modal.doctorappointment_modal.doctorappointmentreject_modal .modal-content .modal-body {
  padding: 0px 20px 20px;
}
.modal .modal-dialog.custom_modal.doctorappointment_modal.doctorappointmentreject_modal .modal-content .modal-body .form-control {
  padding: 15px;
  margin-top: 20px;
}
.modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-header {
  border-bottom: none;
}
.modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-body {
  text-align: center;
  padding: 0px 45px 30px;
}
.modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-body > p {
  margin-bottom: 20px;
  line-height: normal;
  color: #8B8F95;
  font-size: 16px;
}
.modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-body span {
  font-size: 16px;
  color: #8B8F95;
}
.modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-body span span {
  color: #1C1D1F;
}
.modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-footer {
  display: flex;
  justify-content: space-around;
  padding: 0;
}
.modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-footer .divider {
  position: relative;
}
.modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-footer .divider:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 25px;
  background-color: #DBDBDB;
  top: 50%;
  transform: translateY(-50%);
}
.modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-footer .btn {
  color: #8B8F95;
  font-size: 15px;
  font-family: "GTWalsheimPro-Medium";
  width: 50%;
  padding: 13px 30px;
}
.modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-footer .btn ~ .btn {
  color: #3E454F;
}
@media (max-width: 1399px) {
  .modal .modal-dialog.custom_modal.write_review_modal .modal-content .modal-body .form-group {
    margin-bottom: 20px;
  }
  .modal .modal-dialog.custom_modal.write_review_modal .modal-content .modal-body .form-group .underline_label {
    font-size: 13px;
  }
  .modal .modal-dialog.custom_modal.write_review_modal .modal-content .modal-body .form-group .form-control {
    padding: 15px ​15px;
  }
  .modal .modal-dialog.custom_modal.change_password_modal .modal-content .modal-body .changepw_info {
    font-size: 15px;
  }
  .modal .modal-dialog.custom_modal.change_password_modal .modal-content .modal-body .form-group {
    margin-bottom: 30px;
  }
  .modal .modal-dialog.custom_modal.change_password_modal .modal-content .modal-body .btn {
    font-size: 13px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body {
    padding: 25px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .profile > img {
    height: 80px;
    width: 80px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .profile .upload_btn {
    width: 36px;
    height: 36px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .profile .upload_btn > img {
    max-height: 15px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .btn {
    font-size: 13px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .btn_primary {
    font-size: 13px;
    min-height: auto;
  }
  .modal .modal-dialog.custom_modal.doctorappointment_modal.doctorappointmentreject_modal .modal-content .modal-body .form-control {
    padding: 13px;
  }
  .modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-body > p {
    font-size: 14px;
  }
  .modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-body span {
    font-size: 14px;
  }
  .modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-footer .btn {
    font-size: 13px;
  }
}
@media (max-width: 1199px) {
  .modal .modal-dialog.custom_modal .modal-content .modal-header {
    padding: 30px 25px 15px;
  }
  .modal .modal-dialog.custom_modal .modal-content .modal-header .modal-title {
    font-size: 16px;
  }
  .modal .modal-dialog.custom_modal .modal-content .modal-body {
    padding: 15px 25px;
  }
}
@media (max-width: 767px) {
  .modal .modal-dialog.custom_modal .modal-content .modal-header {
    padding: 25px 20px 12px;
  }
  .modal .modal-dialog.custom_modal .modal-content .modal-body {
    padding: 15px 20px;
  }
  .modal .modal-dialog.custom_modal.change_password_modal .modal-content .modal-body {
    padding: 15px 20px;
  }
  .modal .modal-dialog.custom_modal.change_password_modal .modal-content .modal-body .changepw_info {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body {
    padding: 15px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload {
    margin-bottom: 30px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .profile {
    margin-right: 15px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .profile > img {
    height: 60px;
    width: 60px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .profile .upload_btn {
    width: 26px;
    height: 26px;
  }
  .modal .modal-dialog.custom_modal.editprofile_modal .modal-content .modal-body .editprofile_view .profile_upload .profile .upload_btn > img {
    max-height: 12px;
  }
  .modal .modal-dialog.custom_modal.doctorappointment_modal.doctorappointmentreject_modal .modal-content .modal-body {
    padding: 0px 15px 20px;
  }
  .modal .modal-dialog.custom_modal.doctorappointment_modal .modal-content .modal-body {
    padding: 0px 15px 20px;
  }
}

.breadcrumb_sec {
  padding: 20px 0;
}
.breadcrumb_sec.light_gray {
  background-color: #f6f8fa;
}
@media (max-width: 991px) {
  .breadcrumb_sec {
    padding: 15px 0;
  }
}
@media (max-width: 767px) {
  .breadcrumb_sec {
    padding: 10px 0;
  }
}

.slider_sec.users_say_sec .heading {
  padding-top: 30px;
  margin-bottom: 50px;
}
.slider_sec.users_say_sec .slider_view .slick-slider .slick-list {
  margin: 0 -10px;
  padding: 42px 0 0;
}
.slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-prev {
  left: -65px;
}
.slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-next {
  right: -65px;
}
.slider_sec.users_say_sec .slider_view .slide_item .content {
  background-color: #ffffff;
  text-align: center;
  position: relative;
  padding: 51px 30px 35px;
  border-radius: 4px;
  margin: 0 10px;
  min-height: 287px;
}
.slider_sec.users_say_sec .slider_view .slide_item .content img {
  height: 84px;
  width: 84px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: translate(0, -50%);
  display: inline-block;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.slider_sec.users_say_sec .slider_view .slide_item .content h5 {
  font-size: 30px;
  color: #1C1D1F;
  font-family: "GTWalsheimPro-Medium";
  margin-bottom: 7px;
  line-height: normal;
}
.slider_sec.users_say_sec .slider_view .slide_item .content span {
  letter-spacing: 0.32px;
  font-size: 16px;
  color: #8B8F95;
  display: block;
  line-height: normal;
}
.slider_sec.users_say_sec .slider_view .slide_item .content p {
  margin-top: 30px;
  font-size: 18px;
  letter-spacing: 0.36px;
  margin-bottom: 0;
  line-height: 28px;
  color: #8B8F95;
}
@media (max-width: 1680px) {
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-prev {
    left: -75px;
  }
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-next {
    right: -75px;
  }
}
@media (max-width: 1600px) {
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-prev {
    left: -55px;
  }
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-next {
    right: -55px;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content p {
    font-size: 16px;
  }
}
@media (max-width: 1440px) {
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-list {
    padding: 37px 0 0;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content {
    padding: 45px 25px 25px;
    min-height: 300px;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content img {
    height: 74px;
    width: 74px;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content h5 {
    font-size: 24px;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content span {
    font-size: 15px;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content p {
    margin-top: 25px;
    font-size: 15px;
    line-height: 25px;
  }
}
@media (max-width: 1399px) {
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-prev {
    left: -60px;
  }
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-next {
    right: -60px;
  }
}
@media (max-width: 1280px) {
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-prev {
    left: -42px;
  }
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-next {
    right: -42px;
  }
}
@media (max-width: 1199px) {
  .slider_sec.users_say_sec .heading {
    margin-bottom: 40px;
  }
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-list {
    padding: 33px 0 0;
  }
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-prev {
    left: -35px;
  }
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-next {
    right: -35px;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content {
    padding: 40px 20px 20px;
    min-height: 245px;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content img {
    height: 64px;
    width: 64px;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content h5 {
    font-size: 20px;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content span {
    font-size: 14px;
  }
  .slider_sec.users_say_sec .slider_view .slide_item .content p {
    margin-top: 20px;
    font-size: 14px;
    line-height: 23px;
  }
}
@media (max-width: 991px) {
  .slider_sec.users_say_sec .heading {
    margin-bottom: 30px;
  }
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow {
    top: -42px;
  }
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-prev {
    left: auto;
    right: 30px;
  }
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-next {
    right: 0;
  }
}
@media (max-width: 767px) {
  .slider_sec.users_say_sec .slider_view .slick-slider .slick-arrow.slick-prev {
    right: 25px;
  }
}

.rating_view_concept {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.rating_view_concept input {
  display: none;
}
.rating_view_concept label {
  color: #DBDBDB;
  cursor: pointer;
  margin-bottom: 0;
  line-height: normal;
  margin-right: 12px;
}
.rating_view_concept label:before {
  content: "\f005";
  font-size: 30px;
  font-family: "FontAwesome";
  display: inline-block;
}
.rating_view_concept > input:checked ~ label {
  color: #FFA500;
}
.rating_view_concept > input:hover ~ label {
  color: #FFA500;
}

.scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 4px;
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #8B8F95;
  box-shadow: inset 0 0 6px #8B8F95;
  border-radius: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #324688;
  border-radius: 10px;
}

.slots {
  padding: 0 32px;
}
.slots.doctor_appointment_slides {
  padding: 0;
}
.slots.doctor_appointment_slides .slots_date {
  display: flex;
  margin-bottom: 35px;
  align-items: center;
}
.slots.doctor_appointment_slides .slots_date .slots_date_pick {
  display: inline-flex;
}
.slots.doctor_appointment_slides .slots_date .slots_date_pick input {
  background-color: #ffffff;
  border: none;
  height: 46px;
  padding: 13px;
  border-radius: 4px;
  color: #8B8F95;
  font-size: 16px;
}
.slots.doctor_appointment_slides .slots_date .slots_date_pick input:focus {
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  -ms-outline: none;
  -o-outline: none;
}
.slots.doctor_appointment_slides .slots_date .slots_date_pick input::-webkit-calendar-picker-indicator {
  background: url("../../images/calendar_icon.png") no-repeat;
}
.slots.doctor_appointment_slides .slots_date .controls {
  width: calc(100% - 195px);
  margin-left: 20px;
  margin-bottom: 0;
}
.slots.doctor_appointment_slides .slots_date .controls .slick-list .slick-track {
  margin: 0;
}
.slots.doctor_appointment_slides .controls {
  padding: 0 32px;
  margin-bottom: 35px;
}
.slots.doctor_appointment_slides .slick-arrow.slick-prev {
  left: 0;
}
.slots.doctor_appointment_slides .slick-arrow.slick-next {
  right: 0;
}
.slots.doctor_appointment_slides .content_slider {
  margin-bottom: 0px;
}
.slots.doctor_appointment_slides .content_slider .doctor_box {
  margin-bottom: 25px;
}
.slots.doctor_appointment_slides .content_slider .doctor_box .media-body .heading_like.patient_view {
  margin-bottom: 7px;
  cursor: pointer;
}
.slots.doctor_appointment_slides .content_slider .doctor_box .media-body .heading_like.patient_view h3 {
  max-width: 140px;
}
.slots.doctor_appointment_slides .content_slider .doctor_box .media-body .heading_like.patient_view span {
  color: #324688;
  display: inline-flex;
  font-size: 14px;
  align-items: center;
  line-height: normal;
}
.slots.doctor_appointment_slides .content_slider .doctor_box .media-body .heading_like.patient_view span > img {
  margin-right: 5px;
}
.slots.doctor_appointment_slides .content_slider .doctor_box .media-body .doctor_calling_system {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.slots.doctor_appointment_slides .content_slider .doctor_box .media-body .doctor_calling_system .left_view {
  line-height: normal;
}
.slots.doctor_appointment_slides .content_slider .doctor_box .media-body .doctor_calling_system .left_view .btn {
  font-size: 16px;
  font-family: "GTWalsheimPro-Medium";
  color: #038B57;
}
.slots.doctor_appointment_slides .content_slider .doctor_box .media-body .doctor_calling_system .left_view .btn + .btn {
  margin-left: 24px;
  color: #F67054;
}
.slots .slick-arrow.slick-prev, .slots .slick-arrow.slick-next {
  width: 13px;
  height: 23px;
  display: flex;
}
.slots .slick-arrow.slick-prev {
  left: -32px;
}
.slots .slick-arrow.slick-next {
  right: -32px;
}
.slots .controls {
  margin-bottom: 22px;
}
.slots .controls .slick-list .slick-slide.slick-current .tab_item {
  color: #324688;
  font-family: "GTWalsheimPro-Medium";
}
.slots .controls .slick-list .slick-slide .tab_item {
  font-size: 14px;
  color: #8B8F95;
  margin-right: 15px;
  text-align: center;
  cursor: pointer;
}
.slots .controls .slick-list .slick-slide:first-child .tab_item {
  text-align: left;
}
.slots .content_slider {
  margin-bottom: 50px;
}
.slots .content_slider .tab_content ul {
  margin-bottom: 0;
}
.slots .content_slider .tab_content ul li {
  font-size: 14px;
  color: #324688;
  font-family: "GTWalsheimPro-Medium";
  border-radius: 4px;
  background-color: #F2F6F8;
  line-height: normal;
  cursor: pointer;
  width: 90px;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
}
.slots .content_slider .tab_content ul li.active {
  background-color: #324688;
  color: #ffffff;
}
.slots .content_slider .tab_content ul li:not(:last-child) {
  width: 90px;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
}
.slots .btn_primary {
  font-size: 14px;
  padding: 12px 20px;
  font-family: "GTWalsheimPro-Medium";
}
@media (max-width: 1680px) {
  .slots.doctor_appointment_slides .slots_date .slots_date_pick input {
    height: 40px;
    padding: 10px;
    font-size: 14px;
  }
  .slots.doctor_appointment_slides .slots_date .controls {
    width: calc(100% - 173px);
    margin-left: 15px;
  }
  .slots.doctor_appointment_slides .content_slider .doctor_box {
    padding: 15px;
  }
  .slots.doctor_appointment_slides .content_slider .doctor_box .media-body .heading_like.patient_view h3 {
    max-width: 120px;
  }
  .slots.doctor_appointment_slides .content_slider .doctor_box .media-body .heading_like.patient_view span {
    font-size: 13px;
  }
  .slots.doctor_appointment_slides .content_slider .doctor_box .media-body .doctor_calling_system .left_view .btn {
    font-size: 14px;
  }
  .slots.doctor_appointment_slides .content_slider .doctor_box .media-body .doctor_calling_system .left_view .btn + .btn {
    margin-left: 20px;
  }
  .slots.doctor_appointment_slides .content_slider .doctor_box .media-body .doctor_calling_system .call_btns .btn > img {
    max-height: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .slots.doctor_appointment_slides .content_slider .col-xl-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 1399px) {
  .slots .content_slider .tab_content ul li {
    font-size: 13px;
  }
}
@media (max-width: 1199px) {
  .slots {
    padding: 0 25px;
  }
  .slots .slick-arrow.slick-prev, .slots .slick-arrow.slick-next {
    width: 8px;
    height: 16px;
  }
  .slots .slick-arrow.slick-prev {
    left: -25px;
  }
  .slots .slick-arrow.slick-next {
    right: -25px;
  }
  .slots .controls .slick-list .slick-slide .tab_item {
    font-size: 13px;
    margin-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .slots .content_slider {
    margin-bottom: 30px;
  }
  .slots .content_slider .tab_content ul li {
    font-size: 12px;
    padding: 10px;
  }
  .slots .content_slider .tab_content ul li:not(:last-child) {
    margin-right: 7px;
    margin-bottom: 7px;
  }
  .slots .btn_primary {
    padding: 12px 12px;
  }
}
@media (max-width: 767px) {
  .slots.doctor_appointment_slides .slots_date {
    margin-bottom: 20px;
  }
  .slots.doctor_appointment_slides .controls {
    padding: 0 20px;
    margin-bottom: 20px;
  }
  .slots.doctor_appointment_slides .content_slider .doctor_box {
    margin-bottom: 15px;
  }
  .slots.doctor_appointment_slides .content_slider .doctor_box .media-body .heading_like.patient_view h3 {
    max-width: 250px;
  }
  .slots.doctor_appointment_slides .content_slider .doctor_box .media-body .heading_like.patient_view span {
    font-size: 12px;
  }
  .slots.doctor_appointment_slides .content_slider .doctor_box .media-body .doctor_calling_system .left_view .btn + .btn {
    margin-left: 15px;
  }
}
@media (max-width: 575px) {
  .slots.doctor_appointment_slides .content_slider .doctor_box .media-body .heading_like.patient_view h3 {
    max-width: 110px;
  }
}

.common_picker {
  width: 100%;
}
.common_picker .select__control {
  border: none;
  border-bottom: 1px solid #DBDBDB;
  background-color: #ffffff;
  border-radius: 0;
  min-height: 30px;
  box-shadow: none;
  padding: 0px 0px 2px;
}
.common_picker .select__control:focus, .common_picker .select__control:hover {
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  -ms-outline: none;
  -o-outline: none;
  border-color: #DBDBDB;
}
.common_picker .select__control .select__value-container {
  padding: 0;
  line-height: 20px;
}
.common_picker .select__control .select__value-container .select__placeholder,
.common_picker .select__control .select__value-container .select__single-value {
  margin: 0;
}
.common_picker .select__control .select__value-container .select__placeholder {
  color: #8B8F95;
  font-size: 16px;
}
.common_picker .select__control .select__value-container .select__single-value {
  color: #3E454F;
  font-size: 18px;
}
.common_picker .select__control .select__value-container .select__input {
  color: #3E454F;
}
.common_picker .select__control .select__indicators:hover {
  color: #3E454F;
}
.common_picker .select__control .select__indicators .select__indicator-separator {
  display: none;
}
.common_picker .select__control .select__indicators .select__indicator {
  padding: 0;
  color: #3E454F;
}
.common_picker .select__menu {
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: #ffffff;
  padding: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.03);
}
.common_picker .select__menu .select__menu-list {
  padding: 0;
}
.common_picker .select__menu .select__menu-list .select__option {
  font-size: 16px;
  color: #3E454F;
  cursor: pointer;
}
.common_picker .select__menu .select__menu-list .select__option.select__option--is-focused {
  background-color: transparent;
}
.common_picker .select__menu .select__menu-list .select__option.select__option--is-selected {
  background-color: #324688;
  color: #ffffff;
}
.common_picker .select__menu .select__menu-list .select__option:hover.select__option--is-focused {
  background-color: transparent;
}
.common_picker .select__menu .select__menu-list .select__option:hover.select__option--is-selected {
  background-color: #324688;
  color: #ffffff;
}
.common_picker .select__menu .select__menu-list .select__menu-notice {
  font-size: 16px;
  color: #3E454F;
}
@media (max-width: 1680px) {
  .common_picker .select__control .select__value-container .select__placeholder {
    font-size: 15px;
  }
  .common_picker .select__control .select__value-container .select__single-value {
    font-size: 15px;
  }
  .common_picker .select__menu .select__menu-list .select__option {
    font-size: 15px;
  }
  .common_picker .select__menu .select__menu-list .select__menu-notice {
    font-size: 15px;
  }
}
@media (max-width: 1440px) {
  .common_picker .select__control .select__value-container .select__placeholder {
    font-size: 14px;
  }
  .common_picker .select__control .select__value-container .select__single-value {
    font-size: 14px;
  }
  .common_picker .select__menu .select__menu-list .select__option {
    font-size: 14px;
  }
  .common_picker .select__menu .select__menu-list .select__menu-notice {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .common_picker .select__control .select__value-container .select__placeholder {
    font-size: 13px;
  }
  .common_picker .select__control .select__value-container .select__single-value {
    font-size: 13px;
  }
  .common_picker .select__menu .select__menu-list .select__option {
    font-size: 13px;
  }
  .common_picker .select__menu .select__menu-list .select__menu-notice {
    font-size: 13px;
  }
}

.prescription_box {
  margin-bottom: 25px;
}
.prescription_box .top_view {
  background-color: #F2F6F8;
  padding: 15px 15px 10px;
  border-radius: 6px 6px 0px 0px;
  height: 80px;
}
.prescription_box .top_view h6 {
  font-size: 15px;
  font-family: "GTWalsheimPro-Medium";
  margin-bottom: 6px;
}
.prescription_box .top_view p {
  font-size: 13px;
  color: #8B8F95;
  margin-bottom: 0;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.prescription_box .bottom_view {
  background-color: #F2F6F8;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #ffffff;
  border-radius: 0px 0px 6px 6px;
}
.prescription_box .bottom_view span {
  color: #1C1D1F;
  align-items: baseline;
  display: inline-flex;
  font-size: 13px;
}
.prescription_box .bottom_view span:not(:last-child) {
  border-right: 1px solid #ffffff;
  padding-right: 15px;
  margin-right: 16px;
}
.prescription_box .bottom_view span b {
  font-weight: normal;
  font-family: "GTWalsheimPro-Medium";
  margin-right: 4px;
}
@media (max-width: 1680px) {
  .prescription_box .bottom_view {
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
  }
  .prescription_box .bottom_view::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 10px;
  }
  .prescription_box .bottom_view::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #8B8F95;
    box-shadow: inset 0 0 6px #8B8F95;
    border-radius: 10px;
  }
  .prescription_box .bottom_view::-webkit-scrollbar-thumb {
    background-color: #324688;
    border-radius: 10px;
  }
}

.editor_wrapper {
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
  min-height: 215px;
  justify-content: space-between;
}
.editor_wrapper .editor_toolbar {
  padding: 0;
  border-radius: 0;
  border: none;
  margin: 0;
  font-size: 13px;
  color: inherit;
}
.editor_wrapper .editor_toolbar > div {
  margin-bottom: 0;
}
.editor_wrapper .editor_toolbar > div > div {
  height: auto;
  border: none;
}
.editor_wrapper .editor_toolbar > div > div:hover, .editor_wrapper .editor_toolbar > div > div:focus, .editor_wrapper .editor_toolbar > div > div:active, .editor_wrapper .editor_toolbar > div > div.rdw-option-active {
  box-shadow: none !important;
  background-color: rgba(139, 143, 149, 0.4);
}
.editor_wrapper .editor_toolbar > div > div.tools {
  height: 27px;
}
.editor_wrapper .editor_toolbar > div > div.tools a {
  color: #8B8F95;
}
.editor_wrapper .editor_toolbar > div:not(:last-child) {
  border-right: 2px solid #DBDBDB;
  padding-right: 20px;
  margin-right: 20px;
}
.editor_wrapper .editor_inner .public-DraftEditorPlaceholder-inner {
  font-size: 14px;
  color: #8B8F95;
}
.editor_wrapper .editor_inner .public-DraftStyleDefault-block {
  margin-top: 0;
}

.doctor_app_tabs_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}