@mixin text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin clearfix() {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}
@mixin no-outline() {
    outline: none;
    -moz-outline: none;
    -webkit-outline: none;
    -ms-outline: none;
    -o-outline: none;
}
@mixin animation($animation) {
    -webkit-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
}
@mixin animation-name($name) {
    -webkit-animation-name: $name;
    animation-name: $name;
}
@mixin animation-duration($duration) {
    -webkit-animation-duration: $duration;
    animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
    -webkit-animation-timing-function: $timing-function;
    animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
    -webkit-animation-delay: $delay;
    animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
    -webkit-animation-iteration-count: $iteration-count;
    animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
    -webkit-animation-direction: $direction;
    animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
    -webkit-animation-fill-mode: $fill-mode;
    animation-fill-mode: $fill-mode;
}

@mixin scale($ratio...) {
    -webkit-transform: scale($ratio);
    -ms-transform: scale($ratio);
    -o-transform: scale($ratio);
    transform: scale($ratio);
}
@mixin scaleX($ratio) {
    -webkit-transform: scaleX($ratio);
    -ms-transform: scaleX($ratio); // IE9 only
    -o-transform: scaleX($ratio);
    transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
    -webkit-transform: scaleY($ratio);
    -ms-transform: scaleY($ratio); // IE9 only
    -o-transform: scaleY($ratio);
    transform: scaleY($ratio);
}
@mixin skew($x, $y) {
    -webkit-transform: skewX($x) skewY($y);
    -ms-transform: skewX($x) skewY($y);
    -o-transform: skewX($x) skewY($y);
    transform: skewX($x) skewY($y);
}
@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9 only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees); // IE9 only
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}
@mixin rotateX($degrees) {
    -webkit-transform: rotateX($degrees);
    -ms-transform: rotateX($degrees); // IE9 only
    -o-transform: rotateX($degrees);
    transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
    -webkit-transform: rotateY($degrees);
    -ms-transform: rotateY($degrees); // IE9 only
    -o-transform: rotateY($degrees);
    transform: rotateY($degrees);
}
@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin; // IE9 only
    transform-origin: $origin;
}
@mixin transition($transition...) {
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
@mixin box-shadow($shadow...) {
    box-shadow: $shadow;
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }
    ::-moz-placeholder {
        @content;
    }
    ::-moz-placeholder {
        @content;
    }
    ::-ms-input-placeholder {
        @content;
    }
}

//media screen
@mixin desktop-1680 {
    @media (max-width: 1680px) {
        @content;
    }
}
@mixin desktop-1600 {
    @media (max-width: 1600px) {
        @content;
    }
}
@mixin desktop-1440 {
    @media (max-width: 1440px) {
        @content;
    }
}
@mixin desktop-1399 {
    @media (max-width: 1399px) {
        @content;
    }
}
@mixin desktop-1280 {
    @media (max-width: 1280px) {
        @content;
    }
}
@mixin desktop-screen-1600 {
    @media (min-width: 1600px) and (max-width: 1680px) {
        @content;
    }
}
@mixin laptop-screen-1200 {
    @media (min-width: 1200px) and (max-width: 1440px) {
        @content;
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

//font family
@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false) {
    @font-face {
        font-family: $font-family;
        font-weight: $weight;
        font-style: $style;

        @if $asset-pipeline == true {
            src: font-url("#{$file-path}.eot");
            src: font-url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
                font-url("#{$file-path}.woff") format("woff"), font-url("#{$file-path}.ttf") format("truetype"),
                font-url("#{$file-path}.svg##{$font-family}") format("svg");
        } @else {
            src: url("#{$file-path}.eot");
            src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"), url("#{$file-path}.woff") format("woff"),
                url("#{$file-path}.ttf") format("truetype"), url("#{$file-path}.svg##{$font-family}") format("svg");
        }
    }
}
